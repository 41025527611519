import subCheckTotal from "./operations/subCheckTotal.js"
import subCheckField from "./operations/subCheckField.js"

function verifyLogic(data, operation) {
  const { operationType } = operation
  let result = data

  if (operationType === 'sub.checkTotal') {
    //Logic that checks that the total amount in for example payments doesn't exceed the total amount to pay
    result = subCheckTotal(result, operation)
  }

  if (operationType === 'sub.checkField') {
    result = subCheckField(result, operation)
  }

  if (result) return result
  //ALWAYS RETURN AT THE END OF ALL IF STATEMENTS
  return data;
}

export default verifyLogic