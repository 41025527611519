function extractDataFromPath(state, props) {

    const {arrayPath, data2Copy = [], data2add = []} = props;

    if (!Array.isArray(arrayPath) || arrayPath.length === 0) {
        throw new Error("Path must be a non-empty array.");
    }

    // Parse additional fields into segments
    const parsedAdditionalFields = data2Copy.map(field => {
        // Handle both string format ('lines.itemName') and object format ({'docId': 'id'})
        if (typeof field === 'string') {
            const segments = field.split('.');
            return {
                arrayName: segments[0],
                fieldName: segments[1],
                type: 'direct' // Direct field access from array item
            };
        } else if (typeof field === 'object' && field !== null) {
            const [targetField, sourceField] = Object.entries(field)[0];
            return {
                targetField,
                sourceField,
                type: 'state' // State-based field access
            };
        }
        throw new Error('Invalid field format. Must be string or object.');
    });

    // Process data2add fields
    const staticFields = {};
    data2add.forEach(field => {
        const entries = Object.entries(field);
        if (entries.length > 0) {
            const [key, value] = entries[0];
            staticFields[key] = value;
        }
    });

    function traverse(current, pathIndex, parentValues = {}) {
        // If we've reached the end of the path, return the current value
        if (pathIndex >= arrayPath.length) {
            if (typeof current === 'object' && current !== null && !Array.isArray(current)) {
                return { ...current, ...parentValues, ...staticFields };
            }
            return current;
        }

        // If current is undefined/null, return empty array
        if (current == null) {
            return [];
        }

        const key = arrayPath[pathIndex];

        if (key === '*') {
            // Ensure we're working with an array
            if (!Array.isArray(current)) {
                throw new Error(`Expected an array at path segment '${arrayPath.slice(0, pathIndex + 1).join('.')}'`);
            }

            // Find any additional fields that should be captured at this level
            const fieldsToCapture = parsedAdditionalFields.filter(
                field => field.type === 'direct' ? field.arrayName === arrayPath[pathIndex - 1] : true
            );

            // Map through the array and traverse each item
            return current.reduce((acc, item) => {
                // Capture values from the current level if needed
                const newParentValues = { ...parentValues };
                fieldsToCapture.forEach(field => {
                    if (field.type === 'direct') {
                        // Handle direct field access from array item
                        if (item[field.fieldName] !== undefined) {
                            newParentValues[field.fieldName] = item[field.fieldName];
                        }
                    } else if (field.type === 'state') {
                        // Handle state-based field access
                        if (state[field.sourceField] !== undefined) {
                            newParentValues[field.targetField] = state[field.sourceField];
                        }
                    }
                });

                const result = traverse(item, pathIndex + 1, newParentValues);
                return Array.isArray(result) ? [...acc, ...result] : [...acc, result];
            }, []);
        } else {
            // For regular keys, just traverse deeper
            return traverse(current[key], pathIndex + 1, parentValues);
        }
    }

    // Get the initial result
    let result = traverse(state, 0);

    // Ensure static fields are added to all objects in the final result
    if (Array.isArray(result)) {
        result = result.map(item => {
            if (typeof item === 'object' && item !== null) {
                return { ...item, ...staticFields };
            }
            return item;
        });
    } else if (typeof result === 'object' && result !== null) {
        result = { ...result, ...staticFields };
    }

    return result;
}

export default extractDataFromPath;
