export const pdfData = {
    invoice: {
        images: ['seller/invoiceLogo']
    },
    creditNote: {
        images: ['seller/invoiceLogo']
    },
    stockShipment: {
        images: ['inventoryLogo']
    }
}