import { sum } from './core.js'

const headerSumSubLines = (data, operation) => {
    const { fieldsToOperate, targetField, subEntity, subSubEntity } = operation

    if (data[subEntity] && Array.isArray(data[subEntity])) {
        for (const elem of data[subEntity]) {
            let result = 0
            
            // Check if subSubEntity exists and is an array
            if (elem[subSubEntity] && Array.isArray(elem[subSubEntity]) && elem[subSubEntity].length > 0) {
                for (const subElem of elem[subSubEntity]) {
                    result += sum(subElem, fieldsToOperate)
                }
            }
            
            // Always set the targetField, even if result is 0
            elem[targetField] = parseFloat(result.toFixed(2));
        }
    }
    return data
}

export default headerSumSubLines
