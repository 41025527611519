export const pageViewData = {
    productPage: {
        type: 'simpleCard',
        viewType: 'productCard',
        entity: 'product',
        path: 'product'
    },
    paymentTermPage: {
        type: 'simpleCard',
        viewType: 'paymentTermCard',
        entity: 'paymentTerm',
        path: 'paymentTerm'
    },
    contactPage: {
        type: 'simpleCard',
        viewType: 'contactCard',
        entity: 'contact',
        path: 'contact'
    },
    interactionPage: {
        type: 'simpleCard',
        viewType: 'interactionCard',
        entity: 'interaction',
        path: 'contact/:id/interaction'
    },
    contactPersonPage: {
        type: 'simpleCard',
        viewType: 'contactPersonCard',
        entity: 'contactPerson',
        path: 'contact/:id/contactPerson'
    },
    salesSettingsPage: {
        type: 'simpleCard',
        viewType: 'salesSettingsCard',
        entity: 'salesSettings',
        path: 'salesSettings'
    },
    customerCard: {
        type: 'simpleCard',
        viewType: 'customerCard',
        entity: 'customer',
        path: 'customer'
    },
    organizationCard: {
        type: 'simpleCard',
        viewType: 'organizationCard',
        entity: 'organization',
        path: 'organization'
    },
    userCard: {
        type: 'simpleCard',
        viewType: 'userCard',
        entity: 'organizationUser',
        path: 'organizationUser'
    },
    userCardNew: {
        type: 'simpleCard',
        viewType: 'userCardNew',
        entity: 'organizationUser',
        path: 'organizationUser'
    },
    createOrgCardUser: {
        type: 'simpleCard',
        viewType: 'createOrgCardUser',
        entity: 'user',
        path: 'user'
    },
    createOrgCardOrganization: {
        type: 'simpleCard',
        viewType: 'createOrgCardOrganization',
        entity: 'organization',
        path: 'organization'
    },
    contactMultiPage: {
        type: 'tabView',
        viewType: 'contactTabView',
        entity: 'contact',
        path: 'contact'
    },
    productMultiPage: {
        type: 'tabView',
        viewType: 'productTabView',
        entity: 'product',
        path: 'product'
    },
    invoicePage: {
        type: 'filteredView',
        //Options needed for FilteredView
        filter: {
            filterKey: 'state',
            viewType: 'multiView',
            filterArray: [
                { filterValue: "final", filterResult: "invoiceViewMV" },
                { filterValue: "draft", filterResult: "invoiceEditMV" }
            ]
        },
        entity: 'invoice',
        path: 'invoice',
        loadSettings: 'salesSettings',
    },
    invoiceFromContactPage: {
        type: 'multiView',
        viewType: 'contactInvoiceEditMV',
        entity: 'invoice',
        path: 'invoice',
        loadSettings: 'salesSettings',
        //Triggers the Transition Layer
        transition: {
            active: true,
            type: 'contactToInvoice'
        }
    },
    creditNoteNewPage: {
        type: 'multiView',
        viewType: 'CreditNoteEditMV',
        entity: 'invoice',
        path: 'invoice',
        loadSettings: 'salesSettings',
        //Triggers the Transition Layer
        transition: {
            active: true,
            type: 'invoiceToCreditNote'
        }
    },
    creditNotePage: {
        type: 'filteredView',
        //Options needed for FilteredView
        filter: {
            filterKey: 'state',
            viewType: 'multiView',
            filterArray: [
                { filterValue: "final", filterResult: "CreditNoteViewMV" },
                { filterValue: "draft", filterResult: "CreditNoteEditMV" }
            ]
        },
        entity: 'creditNote',
        path: 'creditNote',
        loadSettings: 'salesSettings',
    },
    warehouseTabPage: {
        type: 'tabView',
        viewType: 'warehouseTabView',
        entity: 'warehouse',
        path: 'warehouse'
    },
    warehousePage: {
        type: 'simpleCard',
        viewType: 'warehouseCard',
        entity: 'warehouse',
        path: 'warehouse'
    },
    hallPage: {
        type: 'simpleCard',
        viewType: 'hallCard',
        entity: 'hall',
        path: 'warehouse/:id/hall'
    },
    rackPage: {
        type: 'simpleCard',
        viewType: 'rackCard',
        entity: 'rack',
        path: 'rack'
    },
    locationPage: {
        type: 'simpleCard',
        viewType: 'locationCard',
        entity: 'location',
        path: 'location'
    },
    stockAdjustmentPage: {
        type: 'filteredView',
        //Options needed for FilteredView
        filter: {
            filterKey: 'state',
            viewType: 'switchView',
            filterArray: [
                { filterValue: "draft", filterResult: "stockAdjustmentCard", filterView: "cardView" },
                { filterValue: "final", filterResult: "stockAdjustmentDisplayView", filterView: "displayView" }
            ]
        },
        entity: 'stockAdjustment',
        path: 'stockAdjustment',
        loadSettings: 'inventorySettings',
    },
    stockMovementPage: {
        type: 'filteredView',
        //Options needed for FilteredView
        filter: {
            filterKey: 'state',
            viewType: 'switchView',
            filterArray: [
                { filterValue: "draft", filterResult: "stockMovementCard", filterView: "cardView" },
                { filterValue: "final", filterResult: "stockAdjustmentDisplayView", filterView: "displayView" }
            ]
        },
        entity: 'stockMovement',
        path: 'stockMovement',
        loadSettings: 'inventorySettings',
    },
    stockShipmentPage: {
        type: 'filteredView',
        //Options needed for FilteredView
        filter: {
            filterKey: 'state',
            viewType: 'multiView',
            filterArray: [
                { filterValue: "final", filterResult: "stockShipmentViewMV" },
                { filterValue: "draft", filterResult: "stockShipmentEditMV" }
            ]
        },
        entity: 'stockShipment',
        path: 'stockShipment',
        loadSettings: 'inventorySettings',
    },
    inventorySettingsPage: {
        type: 'simpleCard',
        viewType: 'inventorySettingsCard',
        entity: 'inventorySettings',
        path: 'inventorySettings'
    },
}