import React from 'react';
import styled from 'styled-components';

const CheckboxWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  margin: ${props => props.$margin || '0'};
  position: relative;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
  margin: 0;
  z-index: 1;
`;

const StyledCheckbox = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid ${props => props.theme.btn.primary};
  border-radius: 4px;
  background-color: ${props => props.$checked ? props.theme.btn.primary : 'white'};
  transition: all 0.3s ease;
  position: relative;
  pointer-events: none;

  ${props => props.$checked && `
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3E%3Cpath d='M13.78 4.22a.75.75 0 00-1.06 0L6.75 10.19l-2.47-2.47a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l6-6a.75.75 0 000-1.06z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
  `}
`;

const CheckboxLabel = styled.span`
  margin-left: 8px;
  pointer-events: none;
`;

const Checkbox = ({ label, checked, onChange, $margin }) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <CheckboxWrapper $margin={$margin}>
      <HiddenCheckbox 
        checked={checked} 
        onChange={handleChange}
      />
      <StyledCheckbox $checked={checked} />
      {label && <CheckboxLabel>{label}</CheckboxLabel>}
    </CheckboxWrapper>
  );
};

export default Checkbox;
