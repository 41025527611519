import { useEffect } from "react"
import styled, { keyframes } from "styled-components"
import Alert from "./Alert";
import { FlexBox } from "../generic/Layout";
import useTranslation from "../../hooks/useTranslation";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const NotificationDiv = styled.div`
    background-color: transparent;
    color: #fff;
//    padding: 8px 16px;
    border-radius: 4px;
    opacity: 0.95;
    transition: opacity 1.5s ease-in-out;
    width: 100%;
//    margin: 8px 0;
`

const NotificationContent = styled(Alert)`
  animation: ${fadeIn} 0.3s ease-in-out forwards;
`

function Notification({ title, message, type = 'info', onClose }) {
    const { t } = useTranslation()

    console.log('Notification: ', title, message, type)


    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 3000); // Set the duration for the notification

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <NotificationDiv>
            <NotificationContent className={type}>
                <FlexBox>
                    <Alert.Icon type={type} />
                    {title && <Alert.Heading>{t(title)}</Alert.Heading>}
                </FlexBox>
                {message && t(message)}
            </NotificationContent>
        </NotificationDiv>
    )
}

export default Notification;
