import styled from "styled-components"
import CardViewFormLines from "../cardView/CardViewFormLines"
import useTranslation from "../../hooks/useTranslation"
import useLocale from "../../hooks/useLocale"
import sumFields from "../../utils/sumFields"
import Warning from "./Warning"
import { getNestedValue } from "../../utils/objects/nestedValue"

const Backgrounder = styled.div`
    background-color: ${({ theme }) => theme.form.panelBg};
    padding: 1rem;
    margin-inline: -1rem;
`
const Description = styled.p`
    margin-top: 0;
`
const Footer = styled.p`
    font-weight: 600;
`

export const retrieveParams = (params, state, lf) => {
    let result = []

    params.forEach(p => {
        let value = p
        if (p.includes('$')) {
            value = p.replace('$', '')
            //This formats Amount values...in params starting with $
            result.push(lf(state[value], 2, 2))
        } else result.push(state[value])
    })
    return result
}

const ModalList = ({ entity, validated, useStore, section, lineId = undefined, path = [] }) => {
    const { m } = useTranslation()
    const { lf } = useLocale()

    // Get state based on path
    const [state, setState] = useStore(s => path.length === 0 ? s : getNestedValue(s, path))

    // Get the list based on the section's linesArray config
    const linesArray = section.meta.linesArray
    const list = state?.[linesArray] || []

    // Calculate totals if needed
    const totalAmount = sumFields(list, section.modal.footer.sumField)
    //state.totalGrossSalesAmount = totalAmount // Set the total in state for the Warning component

    const sectionBody = section.modal.body.description
    const sectionBodyParams = section.modal.body.params
    const bodyParams = retrieveParams(sectionBodyParams, state, lf)
    const footerParams = retrieveParams(section.modal.footer.params, state, lf)
    const footerDescription = section.modal.footer.description

    return (
        <>
            <Description>{m(sectionBody, bodyParams)}</Description>
            <Backgrounder>
                <CardViewFormLines
                    expanded={false}
                    toggleExpanded={() => { }}
                    section={section}
                    linesArray={section.meta.linesArray}
                    entity={entity}
                    key={`${section.meta.name}`}
                    dispatch={() => { }}
                    validated={validated}
                    useStore={useStore}
                    path={path}
                />
            </Backgrounder>
            <Footer>{m(footerDescription, footerParams)}</Footer>
            <Warning warning={section.modal.footer.warning} state={state} compareValue={totalAmount} />
        </>
    )
}

export default ModalList
