import { useState } from "react"
import useNotification from "../../hooks/useNotification"

import useApi from "../../hooks/useApi"
import SpinnerButton from "../generic/SpinnerButton"
import generateEndpoint from "../../utils/generateEndpoint"
import { useParams } from "react-router-dom"
import useTranslation from "../../hooks/useTranslation"

const Action = ({ action, useStore }) => {

    const [data, setData] = useState(null)

    const [state, setState] = useStore(s => s)
    const { showNotification, NotificationComponent } = useNotification();
    console.log('Action: ', action)

    const { postMe } = useApi()
    const label = action['buttonLabel']

    const path = action.endpoint
    const params = useParams()

    const { t } = useTranslation()

    const { id } = params
    const endpoint = generateEndpoint(path, id)


    const sendRequest = async (e) => {
        e.preventDefault()
        const response = await postMe(endpoint, state)
        setData(response)
        if (response.status === 'success') {
            showNotification(response.title, response.message, 'success')
            setState(response.data)
        } else {
            showNotification(response.title, response.error, 'error')
        }
    }

    return (
        <div>
            {NotificationComponent}
            <SpinnerButton onClickEvent={(e) => sendRequest(e)}>{t(label)}</SpinnerButton>
        </div>
    )
}

export default Action