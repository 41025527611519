import { useState } from 'react';
import Notification from '../components/alert/Notification'

function useNotification() {
  const [notification, setNotification] = useState(null);
  const [title, setTitle] = useState(null);
  const [type, setType] = useState(null);

  const showNotification = (title = 'Missing Title', message = 'Missing Message', type = 'success') => { 
    setTitle(title)
    if (message) setNotification(message)
    setType(type)
  };

  const closeNotification = () => {
    setNotification(null)
    setTitle(null)
    setType(null)
  };

  const NotificationComponent = notification && (
    <Notification message={notification} title={title} type={type} onClose={closeNotification} />
  );

  return {
    showNotification,
    NotificationComponent,
  };
}

export default useNotification;
