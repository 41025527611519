import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import addHeaders from "../../utils/addHeader";
import ConnectControl from "./ConnectControl";
import useApi from '../../hooks/useApi';
import { useQuery } from 'react-query';
import generateSetValueObject, { resetSetValueObject } from "./generateSetValueObject";

const SelectContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    position: relative;
`

const DualConnect = ({
    mainEntity,
    subEntity,
    mainValueName,
    mainValueId,
    subValueName,
    mainValueMap,
    subValueMap,
    setMainValue,
    setSubValue,
    options,
    isRed,
    mainModalTitle,
    subModalTitle,
    mainPlaceholder,
    subPlaceholder,
    showCondition = true,
    menuItemField,
    modalType = 'searchList',
    persistMainValue,
    persistSubValue,
}) => {
    const [mainEntityId, setMainEntityId] = useState(mainValueId || null);
    const prevMainEntityId = useRef(); // useRef to persist the previous value across renders

    const { getMe } = useApi();

    const getMainData = async () => {
        const resource = `${mainEntity}`;
        const response = await getMe(resource);
        return addHeaders(response.data || [{ name: 'no results found' }], options?.withHeaders);
    };

    const getSubData = async () => {
        if (!mainEntityId) {
            console.warn('selectedMainEntity or its id is undefined', mainEntityId);
            return [];
        }
        const resource = `${mainEntity}/${mainEntityId}/${subEntity}`;
        //console.log('Fetching sub-entity data with resource:', resource);
        const response = await getMe(resource);
        return addHeaders(response.data || [{ name: 'no results found' }], options?.withHeaders);
    };

    const mainResult = useQuery([`${mainEntity}`], getMainData, { keepPreviousData: true });
    const subResult = useQuery([`${subEntity}`, mainEntityId], getSubData, {
        keepPreviousData: true,
        enabled: !!mainEntityId
    });

    useEffect(() => {
        // Only proceed if mainEntityId has a value and has changed from the previous non-undefined value
        if (mainEntityId && mainEntityId !== undefined && prevMainEntityId.current!==undefined && mainEntityId !== prevMainEntityId.current) {
            // Clear subentity selection when main entity changes
            const result = resetSetValueObject(subValueMap);
            //console.log('[DUAL CONNECT] Value Object: ', result);
            setSubValue(result);
        }

        // Update the ref with the current mainEntityId for the next effect run
        prevMainEntityId.current = mainEntityId;

        //console.log('selectedMainEntity changed:', mainEntityId);
    }, [mainEntityId]);


    const handleMainSelection = (data) => {
        const result = generateSetValueObject(data, mainValueMap);
        console.log('Main entity selected:', result);
        setMainValue(result);
        //console.log('Setting Main Entity Id:', data.id);
        setMainEntityId(data.id);
    };

    const handleSubSelection = (data) => {
        const result = generateSetValueObject(data, subValueMap);
        console.log('Sub entity selected:', result);
        setSubValue(result);
    };

    if (mainResult.isLoading) return <span>Loading main entity data...</span>;
    if (mainResult.isError) return <span>Error loading main entity: {mainResult.error?.message || 'Internet Issues'}</span>;

    //console.log('Rendering DualConnect. selectedMainEntity:', mainEntityId);
    //console.log('subResult:', subResult);

    return (
        <>
            <SelectContainer>
                <ConnectControl
                    valueName={mainValueName}
                    setValue={setMainValue}
                    data={mainResult.data}
                    placeholder={mainPlaceholder}
                    onSelect={handleMainSelection}
                    entity={mainEntity}
                    modalTitle={mainModalTitle}
                    options={options}
                    isRed={isRed}
                    $allwaysOn={true}
                    menuItemField={menuItemField}
                    modalType={modalType}
                    persistValue={persistMainValue}
                />
            </SelectContainer>
            {(mainEntityId && showCondition) && (
                <SelectContainer>
                    {subResult.isLoading ? (
                        <span>Loading sub-entity data...</span>
                    ) : subResult.isError ? (
                        <span>Error loading sub-entity: {subResult.error?.message || 'Internet Issues'}</span>
                    ) : ((subResult.data && subResult.data.list.length > 0) || subValueName) ? (
                        <ConnectControl
                            valueName={subValueName}
                            setValue={setSubValue}
                            data={subResult.data}
                            placeholder={subPlaceholder}
                            onSelect={handleSubSelection}
                            entity={subEntity}
                            modalTitle={subModalTitle}
                            options={options}
                            isRed={isRed}
                            $allwaysOn={true}
                            menuItemField={menuItemField}
                            modalType={modalType}
                            persistValue={persistSubValue}
                        />
                    ) : (
                        <span>No sub-entities available</span>
                    )}
                </SelectContainer>
            )}
        </>
    );
};

export default DualConnect;
