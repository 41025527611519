import { ListHeader, ListCol, ListRow } from "./MultiList";
import useTranslation from "../../hooks/useTranslation";


const ConfirmList = ({ data, col, emptyMessage }) => {
    const { t } = useTranslation()

    console.log('data: ', data)
    const unresData = data.filter(item => item['reservedQuantity'] === undefined);
    const resData = data.filter(item => item['reservedQuantity'] !== undefined);

    return (
        <>
            {(unresData.length === 0) &&
                <ListRow><span>{t(emptyMessage)}</span></ListRow>
            }
            {unresData.length > 0 &&
                <div>
                    <ListHeader>
                        {col.map((col, key) => (
                            <ListCol $flex={col.flex} $margin={col.margin} key={key}>
                                {t(col.title)}
                            </ListCol>
                        ))}
                    </ListHeader>
                    {unresData.map((item, key) => (
                        <ListRow
                            key={key}
                        >
                            {col.map((col, key) => (
                                <ListCol $flex={col.flex} $margin={col.margin} key={key}>
                                    {item[col.field]}
                                </ListCol>
                            ))}
                        </ListRow>
                    ))}
                </div>}
                <p>This Items have been reserved already</p>
                {resData.length > 0 &&
                <div>
                    <ListHeader>
                        {col.map((col, key) => (
                            <ListCol $flex={col.flex} $margin={col.margin} key={key}>
                                {t(col.title)}
                            </ListCol>
                        ))}
                    </ListHeader>
                    {resData.map((item, key) => (
                        <ListRow
                            key={key}
                        >
                            {col.map((col, key) => (
                                <ListCol $flex={col.flex} $margin={col.margin} key={key}>
                                    {item[col.field]}
                                </ListCol>
                            ))}
                        </ListRow>
                    ))}
                </div>}

        </>
    );
};

export default ConfirmList;