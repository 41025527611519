import { Tag } from "../generic/Core"
import useTranslation from "../../hooks/useTranslation"
import useLocale from "../../hooks/useLocale"
import { retrieveParams } from "./ModalList"

const Warning = ({ warning, state, compareValue }) => {
    const { m } = useTranslation()
    const { lf } = useLocale()

    // If no warning config is provided, or condition field doesn't exist in state, don't render
    if (!warning || !warning.condition || !(warning.condition in state)) {
        return null
    }

    // Check if the condition is met (in this case, if totalGrossSalesAmount doesn't match)
    const isWarning = compareValue !== state[warning.condition]
    const warningParams = warning.params ? retrieveParams(warning.params, state, lf) : []

    if (!isWarning) {
        return null
    }

    return (
        <>
            <Tag 
                $variant={warning.tagVariant} 
                $margin='0 0 0.5rem 0'
            >
                {m(warning.tagLabel)}
            </Tag>
            <span style={{ marginLeft: '0.5rem' }}>
                {m(warning.message, [lf(compareValue, 2, 2), ...warningParams])}
            </span>
        </>
    )
}

export default Warning
