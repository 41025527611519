export const multiViewData = {
    invoiceEditMV: {
        mainFormType: 'cardView',
        cardViewType: 'invoiceCard',
        sidePanelType: 'invoiceEditPanel',
        width: '1300px',
    },
    invoiceViewMV: {
        mainFormType: 'displayView',
        displayViewType: 'invoiceDisplayView',
        sidePanelType: 'invoiceViewPanel',
        width: '1300px',
    },
    CreditNoteEditMV: {
        mainFormType: 'cardView',
        cardViewType: 'creditNoteCard',
        sidePanelType: 'invoiceViewPanel',
        width: '1300px',
    },
    CreditNoteViewMV: {
        mainFormType: 'displayView',
        displayViewType: 'creditNoteDisplayView',
        sidePanelType: 'creditNoteViewPanel',
        width: '1300px',
    },
    contactInvoiceEditMV: {
        mainFormType: 'cardView',
        cardViewType: 'contactInvoiceCard',
        sidePanelType: 'invoiceEditPanel',
        width: '1300px',
    },
    stockShipmentEditMV: {
        mainFormType: 'cardView',
        cardViewType: 'stockShipmentCard',
        sidePanelType: 'stockShipmentEditPanel',
        width: '1300px',
    },
    StockShipmentViewMV: {
        mainFormType: 'displayView',
        displayViewType: 'stockShipmentDisplayView',
        sidePanelType: 'stockShipmentViewPanel',
        width: '1300px',
    }
}