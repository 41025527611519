import { Modal } from "react-bootstrap"
import {
    Icon_People, Icon_Boxes, Icon_Delete, Icon_Gear, Icon_Design, Icon_Money,
    Icon_Warehouse, Icon_Hall, Icon_Rack
} from "../../styles/icons"

export const cardViewData = {
    customerCard: {
        titleField: 'name',
        path: 'customer',
        entity: 'customer',
        newTitle: 'customer.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'customerConfirmDelete',
        sections: [{
            meta: {
                type: 'form',
                name: 'customer',
                width: '360px'
            },
            groups: [
                {
                    type: 'text',
                    id: 'name',
                    label: 'customer.form.name',
                    placeholder: 'customer.form.name.placeholder',
                    isRequired: true
                },
                {
                    type: 'email',
                    id: 'email',
                    label: 'customer.form.email',
                    placeholder: 'customer.form.email.placeholder',
                    isRequired: true
                },
                {
                    type: 'text',
                    label: 'customer.form.address',
                    id: 'address_street',
                    placeholder: 'customer.form.address.street',
                    fgMargin: '1.5rem',
                    autoComplete: 'address-line1'
                },
                {
                    type: 'group',
                    fields: [
                        {
                            type: 'text',
                            id: 'address_postCode',
                            placeholder: 'customer.form.address.postcode',
                            autoComplete: 'postal-code'
                        },
                        {
                            type: 'text',
                            id: 'address_city',
                            placeholder: 'customer.form.address.city',
                            autoComplete: 'address-level2'
                        },

                    ]
                },
                {
                    type: 'country',
                    id: 'country',
                    display: 'countryName',
                    valueMap: {
                        'country': 'id',
                        'countryName': 'name',
                    },
                    placeholder: 'customer.form.country',
                    isRequired: true,
                    defaultType: 'country'

                },
                {
                    type: 'description',
                    label: 'contact.form.instruction.label',
                    text: 'contact.form.instruction.text'
                }
            ],
            buttons: [
                {
                    variant: 'primary',
                    type: 'submit',
                    label: 'all.form.button.update'
                }
            ],
            display: {
                emptyField: 'name',
                columns: [
                    {
                        size: 1,
                        grow: '100%',
                        fields: [
                            {
                                size: 'title',
                                type: 'field',
                                value: 'name'
                            },
                            {
                                size: 'regular',
                                type: 'field',
                                value: 'email'
                            },
                            {
                                size: 'regular',
                                type: 'merge',
                                value: '${1} - ${2} ${3} - ${4}',
                                mergeParams: ['address_street', 'address_postCode', 'address_city', 'countryName']
                            }

                        ]
                    }
                ]

            }
        }],
        cardTitle: {
            title: 'customer.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'customer.form.dropdown.menu01',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'customerConfirmDelete'
                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    contactCard: {
        titleField: 'name',
        path: 'contact',
        entity: 'contact',
        newTitle: 'contact.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'contactConfirmDelete',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'contact',
                    width: '1024px'
                },
                groups: [
                    {
                        type: 'select',
                        id: 'companyTypeId',
                        display: 'companyTypeName',
                        valueMap: {
                            'companyTypeName': 'name',
                            'companyTypeId': 'id'
                        },
                        label: 'contact.form.type',
                        entity: 'companyType',
                        placeholder: 'contact.form.type.placeholder',
                        options: { withHeaders: false },
                        isRequired: true,
                        defaultType: 'select'
                    },
                    {
                        type: 'text',
                        id: 'name',
                        label: 'contact.form.name',
                        placeholder: 'contact.form.name.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'email',
                        id: 'email',
                        label: 'contact.form.email',
                        placeholder: 'contact.form.email.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'modal',
                        control: {
                            id: 'addressModal',
                            type: 'text',
                            label: 'contact.form.address',
                            placeholder: 'contact.form.address.placeholder',
                        },
                        descriptions: [
                            {
                                type: 'field',
                                field: 'address_street'
                            },
                            {
                                type: 'text',
                                text: ', '
                            },
                            {
                                type: 'field',
                                field: 'address_postCode'
                            },
                            {
                                type: 'text',
                                text: ' '
                            },
                            {
                                type: 'field',
                                field: 'address_city'
                            }
                        ],
                        modalGroups: [
                            {
                                type: 'text',
                                label: 'contact.form.address',
                                id: 'address_street',
                                placeholder: 'contact.form.address.street',
                                fgMargin: '1.5rem',
                                focus: true,
                                autoComplete: 'address-line1'
                            },
                            {
                                type: 'group',
                                label: 'contact.form.address',
                                focus: false,
                                fields: [
                                    {
                                        type: 'text',
                                        id: 'address_postCode',
                                        placeholder: 'contact.form.address.postcode',
                                        autoComplete: 'postal-code'
                                    },
                                    {
                                        type: 'text',
                                        id: 'address_city',
                                        placeholder: 'contact.form.address.city',
                                        focus: false,
                                        autoComplete: 'address-level2'

                                    },

                                ]
                            }
                        ]
                    },
                    {
                        label: 'all.country',
                        type: 'country',
                        id: 'country',
                        display: 'countryName',
                        valueMap: {
                            'country': 'id',
                            'countryName': 'name',
                        },
                        name: 'country',
                        placeholder: 'contact.form.country',
                        defaultType: 'country'
                    },
                    {
                        type: 'switch',
                        id: 'isCustomer',
                        switchText: 'set as Customer',
                    },
                ],
                buttons: [
                    {
                        type: 'submit',
                        variant: 'primary',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyField: 'name',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'name'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'email'
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '${1} - ${2} ${3} - ${4}',
                                    mergeParams: ['address_street', 'address_postCode', 'address_city', 'countryName']
                                }
                            ]
                        }
                    ]
                }
            }],
        cardTitle: {
            title: 'contact.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'contact.form.dropdown.menu01',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'contactConfirmDelete'
                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    interactionCard: {
        titleField: 'title',
        path: 'contact/:id/interaction',
        entity: 'interaction',
        newTitle: 'interaction.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'interactionConfirmDelete',
        subRoute: true,
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'contactInteraction',
                    width: '1024px'
                },
                groups: [
                    {
                        type: 'date',
                        id: 'date',
                        label: 'interaction.form.date',
                        placeholder: 'interaction.form.date.placeholder',
                        defaultType: 'date'
                    },
                    {
                        type: 'text',
                        id: 'title',
                        label: 'interaction.form.title',
                        placeholder: 'interaction.form.title.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'textarea',
                        id: 'description',
                        label: 'interaction.form.description',
                        rows: 4,
                        placeholder: 'interaction.form.description.placeholder',
                        isRequired: true
                    }
                ],
                buttons: [
                    {
                        type: 'submit',
                        variant: 'primary',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyField: 'title',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'title'
                                },
                                {
                                    size: 'regular',
                                    type: 'date',
                                    value: 'date'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'description'
                                }
                            ]
                        }
                    ]
                }
            }],
        cardTitle: {
            title: 'contact.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'contact.form.dropdown.menu01',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'interactionConfirmDelete'
                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    contactPersonCard: {
        titleField: 'name',
        path: 'contact/:id/contactPerson',
        entity: 'contactPerson',
        newTitle: 'contactPerson.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'contactPersonConfirmDelete',
        subRoute: true,
        sections: [
            {
                meta: {
                    type: 'form',
                    width: '1024px'
                },
                groups: [

                    {
                        type: 'text',
                        id: 'name',
                        label: 'contactPerson.form.name',
                        placeholder: 'contactPerson.form.name.placeholder',
                    },
                    {
                        type: 'email',
                        id: 'email',
                        label: 'contactPerson.form.email',
                        placeholder: 'contactPerson.form.email.placeholder',
                    }
                ],
                buttons: [
                    {
                        variant: 'primary',
                        type: 'submit',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyField: 'name',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'name'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'email'
                                }
                            ]
                        }
                    ]

                }
            }],
        cardTitle: {
            title: 'contact.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'contact.form.dropdown.menu01',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'contactPersonConfirmDelete'
                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    productCard: {
        titleField: 'name',
        path: 'product',
        entity: 'product',
        newTitle: 'product.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'productConfirmDelete',
        width: '1024px',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'product',
                    width: '1024px'
                },
                groups: [
                    {
                        type: 'text',
                        id: 'name',
                        label: 'product.form.name',
                        placeholder: 'product.form.name.placeholder',
                        isRequired: true

                    },
                    {
                        type: 'textarea',
                        id: 'description',
                        label: 'product.form.description',
                        rows: 2,
                        placeholder: 'product.form.description.placeholder',
                        isRequired: true

                    },
                    {
                        type: 'number',
                        id: 'netSalesPrice',
                        label: 'product.form.netSalesPrice',
                        variant: 'price',
                        decimals: 5,
                        placeholder: 'product.form.netSalesPrice.placeholder',
                        isRequired: true,
                        defaultType: 'value',
                        defaultValue: 1

                    },
                    {
                        type: 'select',
                        id: 'unitId',
                        display: 'unitName',
                        label: 'product.form.unitName',
                        entity: 'unit',
                        valueMap: {
                            'unitName': 'name',
                            'unitId': 'id'
                        },
                        placeholder: 'product.form.unitName.placeholder',
                        options: { withHeaders: true },
                        isRequired: true,
                        defaultType: 'select'
                    },
                    {
                        type: 'select',
                        id: 'taxId',
                        display: 'taxName',
                        label: 'product.form.vatTax',
                        entity: 'vatTax',
                        valueMap: {
                            'taxName': 'name',
                            'taxId': 'id',
                            'taxRate': 'rate'
                        },
                        placeholder: 'product.form.unitName.placeholder',
                        options: { withHeaders: false },
                        isRequired: true,
                        defaultType: 'select'
                    },
                    {
                        type: 'date',
                        id: 'createdDate',
                        label: 'product.form.createdDate',
                        placeholder: 'product.form.createdDate.placeholder',
                    },
                    {
                        type: 'text-readOnly',
                        id: 'totalInventory',
                        label: 'product.form.total.inventory',
                    }
                ],
                buttons: [
                    {
                        variant: 'primary',
                        type: 'submit',
                        label: 'all.form.button.update',
                        action: 'submit'
                    }
                ],
                display: {
                    emptyMessage: 'product.form.name.missing.message',
                    emptyField: 'name',
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'name'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'description'
                                }
                            ],
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1} DKK',
                                    mergeParams: ['netSalesPrice']
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '@${1}',
                                    mergeParams: ['unitName']
                                },

                            ]
                        }
                    ]

                }
            },
        ],
        cardTitle: {
            title: 'product.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'product.form.dropdown.menu01',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'productConfirmDelete'

                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    organizationCard: {
        titleField: 'name',
        path: 'organization',
        entity: 'organization',
        newTitle: 'organization.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'organizationConfirmDelete',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'organization',
                    width: '360px'
                },
                groups: [
                    {
                        type: 'text',
                        id: 'name',
                        label: 'organization.form.name',
                        placeholder: 'organization.form.name.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'email',
                        id: 'email',
                        label: 'organization.form.email',
                        placeholder: 'organization.form.email.placeholder',
                    },
                    {
                        type: 'text',
                        label: 'organization.form.address',
                        id: 'address_street',
                        placeholder: 'organization.form.address.street',
                        fgMargin: '1.5rem',
                        autoComplete: 'address-line1'
                    },
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'text',
                                id: 'address_postCode',
                                placeholder: 'organization.form.address.postcode',
                                autoComplete: 'postal-code'
                            },
                            {
                                type: 'text',
                                id: 'address_city',
                                placeholder: 'organization.form.address.city',
                                autoComplete: 'address-level2'
                            },

                        ]
                    },
                    {
                        type: 'country',
                        id: 'country',
                        display: 'countryName',
                        valueMap: {
                            'country': 'id',
                            'countryName': 'name',
                        },
                        placeholder: 'organization.form.country',
                    },
                ],
                buttons: [
                    {
                        variant: 'primary',
                        type: 'submit',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyMessage: 'organization.form.name.missing.message',
                    emptyField: 'name',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'name'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'email'
                                }
                            ]
                        }
                    ]
                }
            }
        ],
        cardTitle: {
            title: 'organization.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'organization.form.dropdown.menu01',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'organizationConfirmDelete'
                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    userCard: {
        titleField: 'name',
        path: 'organizationUser',
        entity: 'organizationUser',
        newTitle: 'user.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'userConfirmDelete',
        sections: [{
            meta: {
                type: 'form',
                name: 'user',
                width: '360px'
            },
            groups: [
                {
                    type: 'text',
                    id: 'name',
                    label: 'user.form.name',
                    placeholder: 'user.form.name.placeholder',
                },
                {
                    type: 'text-readOnly',
                    id: 'email',
                    label: 'user.form.email',
                    placeholder: 'user.form.email.placeholder',
                }
            ],
            buttons: [
                {
                    variant: 'primary',
                    type: 'submit',
                    label: 'all.form.button.update'
                }
            ],
            display: {
                emptyMessage: 'organization.form.name.missing.message',
                emptyField: 'name',
                columns: [
                    {
                        size: 1,
                        grow: '100%',
                        fields: [
                            {
                                size: 'title',
                                type: 'field',
                                value: 'name'
                            },
                            {
                                size: 'regular',
                                type: 'field',
                                value: 'email'
                            }
                        ]
                    }
                ]
            }
        }],
        cardTitle: {
            title: 'user.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'user.form.dropdown.menu01',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'userConfirmDelete'

                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    userCardNew: {
        titleField: 'name',
        path: 'organizationUser',
        entity: 'organizationUser',
        newTitle: 'user.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'userConfirmDelete',
        sections: [{
            meta: {
                type: 'form',
                name: 'newUser',
                width: '360px'
            },
            groups: [
                {
                    type: 'text',
                    id: 'name',
                    label: 'user.form.name',
                    placeholder: 'user.form.name.placeholder',
                },
                {
                    type: 'email',
                    id: 'email',
                    label: 'user.form.email',
                    placeholder: 'user.form.email.placeholder',
                },
                {
                    type: 'password',
                    id: 'password',
                    label: 'user.form.password',
                    placeholder: 'user.form.password.placeholder',
                }
            ],
            buttons: [
                {
                    variant: 'primary',
                    type: 'submit',
                    label: 'all.form.button.update'
                }
            ],
            display: {
                emptyMessage: 'organization.form.name.missing.message',
                emptyField: 'name',
                columns: [
                    {
                        size: 1,
                        grow: '100%',
                        fields: [
                            {
                                size: 'title',
                                type: 'field',
                                value: 'name'
                            },
                            {
                                size: 'regular',
                                type: 'field',
                                value: 'email'
                            }
                        ]
                    }
                ]
            }
        }],
        cardTitle: {
            title: 'user.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'user.form.dropdown.menu01',
                    action: 'delete'
                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    createOrgCardUser: {
        titleField: 'name',
        noCardTitle: true,
        path: 'user',
        entity: 'user',
        newTitle: 'createOrg.form.user.title',
        sections: [{
            meta: {
                type: 'form',
                name: 'createOrgUser',
                width: '480px',
                variant: 'transparent',
                collapsible: false,
            },
            groups: [
                {
                    type: 'text',
                    id: 'name',
                    label: 'createOrg.form.user.name',
                    placeholder: 'createOrg.form.user.placeholder',
                }],
            buttons: [
                {
                    variant: 'primary',
                    type: 'saveNContinue',
                    label: 'createOrg.form.user.button',
                    action: 'saveNContinue'
                }
            ],
            display: {
                emptyMessage: 'organization.form.name.missing.message',
                emptyField: 'name',
                columns: [
                    {
                        size: 1,
                        grow: '100%',
                        fields: [
                            {
                                size: 'title',
                                type: 'field',
                                value: 'name'
                            },
                            {
                                size: 'regular',
                                type: 'field',
                                value: 'email'
                            }
                        ]
                    }
                ]
            }

        }],
        cardTitle: {
            title: 'organization.form.new.title',
            menuItems: [
            ]
        }
    },
    createOrgCardOrganization: {
        titleField: 'name',
        noCardTitle: true,
        path: 'organization',
        entity: 'organization',
        newTitle: 'organization.form.new.title',
        noNavigation: true,
        sections: [{
            meta: {
                type: 'form',
                name: 'createOrgOrg',
                width: '480px',
                collapsible: false,
            },
            groups: [
                {
                    type: 'text',
                    id: 'name',
                    label: 'createOrg.form.org.name',
                    placeholder: 'createOrg.form.org.name.placeholder',
                },
                {
                    type: 'email',
                    id: 'email',
                    label: 'createOrg.form.org.email',
                    placeholder: 'createOrg.form.org.email.placeholder',
                },
                {
                    type: 'text',
                    label: 'createOrg.form.org.address',
                    id: 'address_street',
                    placeholder: 'createOrg.form.org.address.street',
                    fgMargin: '1.5rem',
                    autoComplete: 'address-line1'
                },
                {
                    type: 'group',
                    fields: [
                        {
                            type: 'text',
                            id: 'address_postCode',
                            placeholder: 'createOrg.form.org.address.postcode',
                            autoComplete: 'postal-code'
                        },
                        {
                            type: 'text',
                            id: 'address_city',
                            placeholder: 'createOrg.form.org.address.city',
                            autoComplete: 'address-level2'
                        },

                    ]
                },
                {
                    type: 'country',
                    id: 'country',
                    display: 'countryName',
                    isRequired: true,
                    valueMap: {
                        'country': 'id',
                        'countryName': 'name',
                    },
                    name: 'country',
                    placeholder: 'organization.form.country',
                },
            ],
            buttons: [
                {
                    variant: 'secondary',
                    type: 'back',
                    label: 'createOrg.form.org.button.back',
                    action: 'back'
                },
                {
                    variant: 'primary',
                    type: 'saveNContinue',
                    label: 'createOrg.form.org.button',
                    action: 'saveNContinue'
                }

            ],
            display: {
                emptyMessage: 'organization.form.name.missing.message',
                emptyField: 'name',
                columns: [
                    {
                        size: 1,
                        grow: '100%',
                        fields: [
                            {
                                size: 'title',
                                type: 'field',
                                value: 'name'
                            },
                            {
                                size: 'regular',
                                type: 'field',
                                value: 'email'
                            }
                        ]
                    }
                ]
            }
        }],
        cardTitle: {
            title: 'organization.form.new.title',
            menuItems: [
            ]
        }
    },
    invoiceCard: {
        titleField: 'number',
        path: 'invoice',
        entity: 'invoice',
        newTitle: 'invoice.form.new.title',
        backOnSubmit: true,
        backOverride: '/sales/invoice',
        collapseOnSubmit: true,
        onDeleteConfirm: 'invoiceConfirmDelete',
        settingDestination: 'seller',
        width: '',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'invoiceNumber',
                    width: '800px',
                },
                groups: [
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'nserie',
                                id: 'number',
                                label: 'invoice.form.number',
                                placeholder: 'invoice.form.number.placeholder',
                                isRequired: true,
                                defaultType: 'nserie',
                                entity: 'invoice'
                            },
                            {
                                label: 'invoice.form.date',
                                type: 'date',
                                id: 'date',
                                placeholder: 'invoice.form.date.placeholder',
                                isRequired: true,
                                defaultType: 'date',
                                align: 'right',
                                linkedFields: ['paymentTermId', 'date', 'paymentTermDays', 'paymentDate']
                            },
                        ]
                    },
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'select',
                                id: 'paymentTermId',
                                display: 'paymentTermId',
                                label: 'invoice.form.paymentTerm',
                                entity: 'paymentTerm',
                                valueMap: {
                                    'paymentTermId': 'id',
                                    'paymentTermName': 'name',
                                    'paymentTermDays': 'days',
                                    'paymentSchedule': 'paymentSchedule',
                                    'paymentInstallments': 'installments',
                                    'paymentIntervalDays': 'intervalDays'
                                },
                                options: { withHeaders: false, displayId: true },
                                isRequired: true,
                                defaultType: 'select',
                                linkedFields: ['paymentTermId', 'date', 'paymentTermDays', 'paymentDate', 'numPayments', 'totalGrossSalesAmount', 'id']
                            },
                            {
                                label: 'invoice.form.paymentDate',
                                type: 'date',
                                id: 'paymentDate',
                                placeholder: 'invoice.form.paymentDate.placeholder',
                                isRequired: true,
                                defaultType: 'date',
                                linkedFields: ['paymentTermId', 'date', 'paymentTermDays', 'paymentDate']
                            },
                        ]
                    },
                    {
                        type: 'textarea',
                        id: 'message',
                        label: 'invoice.form.message',
                        placeholder: 'invoice.form.message.placeholder',
                    }
                ],
                buttons: [
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'invoice.form.number.missing.message',
                    emptyField: 'number',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    prefix: 'invoice.form.number.title.prefix',
                                    size: 'title',
                                    type: 'field',
                                    value: 'number'
                                },
                                {
                                    size: 'regular',
                                    type: 'date',
                                    value: 'date'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'invoiceCustomer',
                    width: '800px'
                },
                groups: [
                    {
                        type: 'dualconnect',
                        id: 'customerId',
                        subId: 'contactPersonId',
                        mainEntity: 'contact',
                        subEntity: 'contactPerson',
                        display: 'customerName',
                        subDisplay: 'contactPersonName',
                        valueId: 'customerId',
                        label: 'invoice.form.customerName',
                        mainModalTitle: 'invoice.form.customer.modal.title',
                        subModalTitle: 'invoice.form.contactPerson.modal.title',
                        mainPlaceholder: 'invoice.form.customerName.placeholder',
                        subPlaceholder: 'invoice.form.contactPerson.name.placeholder',
                        mainValueMap: {
                            'customerName': 'name',
                            'customerId': 'id',
                            'customerEmail': 'email',
                            'customerAddress_city': 'address_city',
                            'customerAddress_postCode': 'address_postCode',
                            'customerAddress_street': 'address_street',
                            'customerCountry': 'country',
                            'customerCountryName': 'countryName',
                            'companyTypeId': 'companyTypeId'
                        },
                        subValueMap: {
                            'contactPersonName': 'name',
                            'contactPersonId': 'id',
                            'customerEmail': 'email'
                        },
                        options: { withHeaders: false },
                        isRequired: true,
                        showCondition: { field: 'companyTypeId', value: 'company' }
                    },
                    {
                        type: 'email',
                        id: 'customerEmail',
                        label: 'invoice.form.customerEmail',
                        placeholder: 'invoice.form.customerEmail.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'text',
                        label: 'invoice.form.customerAddress',
                        id: 'customerAddress_street',
                        placeholder: 'invoice.form.customerAddress.street',
                        autoComplete: 'address-line1'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'text',
                                id: 'customerAddress_postCode',
                                placeholder: 'invoice.form.customerAddress.postcode',
                                autoComplete: 'postal-code'
                            },
                            {
                                type: 'text',
                                id: 'customerAddress_city',
                                placeholder: 'invoice.form.customerAddress.city',
                                focus: false,
                                autoComplete: 'address-level2'
                            },

                        ]
                    },
                    {
                        type: 'country',
                        id: 'customerCountry',
                        display: 'customerCountryName',
                        defaultType: 'country',
                        valueMap: {
                            'customerCountry': 'id',
                            'customerCountryName': 'name',
                        },
                        placeholder: 'invoice.form.customerCountry',
                        fgMargin: '1.5rem'
                    },

                ],
                buttons: [
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'invoice.form.customer.missing.message',
                    editPos: '10%',
                    emptyField: 'customerName',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'customerName',
                                    icon: Icon_People,
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'customerEmail'
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '${1} - ${2} ${3} - ${4}',
                                    mergeParams: ['customerAddress_street', 'customerAddress_postCode', 'customerAddress_city', 'customerCountryName']
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'lines',
                    linesArray: 'lines',
                    linesAddAction: 'invoice.form.line.add.item',
                    name: 'invoiceLines',
                    width: '800px',
                    nFields: { 'netSalePrice': true, 'itemQuantity': true }
                },
                groups: [
                    {

                        type: 'connect',
                        id: 'itemId',
                        display: 'itemName',
                        label: 'invoice.form.itemName',
                        placeholder: 'invoice.form.itemName.placeholder',
                        modalTitle: 'invoice.form.item.modal.title',
                        entity: 'product',
                        valueMap: {
                            'itemId': 'id',
                            'itemName': 'name',
                            'itemDescription': 'description',
                            'netSalesPrice': 'netSalesPrice',
                            'unitId': 'unitId',
                            'unitName': 'unitName',
                            'itemTaxId': 'taxId',
                            'itemTaxName': 'taxName',
                            'itemTaxRate': 'taxRate'
                        },
                        options: { withHeaders: true, nFields: { 'netSalesPrice': true } },
                        isRequired: true
                    },
                    {
                        type: 'textarea',
                        rows: 2,
                        label: 'invoice.form.itemDescription',
                        id: 'itemDescription',
                        placeholder: 'invoice.form.itemDescription.placeholder'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'number',
                                id: 'netSalesPrice',
                                placeholder: 'invoice.form.netPrice',
                                label: 'invoice.form.netPrice',
                                variant: 'price',
                                isRequired: true,
                                decimals: 5,
                                defaultType: 'number',
                                defaultValue: 1,
                            },
                            {
                                type: 'select',
                                id: 'itemTaxId',
                                display: 'itemTaxName',
                                label: 'product.form.vatTax',
                                entity: 'vatTax',
                                valueMap: {
                                    'itemTaxName': 'name',
                                    'itemTaxId': 'id',
                                    'itemTaxRate': 'rate'
                                },
                                placeholder: 'product.form.unitName.placeholder',
                                options: { withHeaders: false },
                                isRequired: true,
                                defaultType: 'select'
                            }
                        ]
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'number',
                                id: 'itemQuantity',
                                placeholder: 'invoice.form.itemQuantity',
                                label: 'invoice.form.itemQuantity',
                                variant: 'quantity',
                                defaultType: 'number',
                                defaultValue: 1,
                                decimals: 5,
                                isRequired: true
                            },
                            {
                                type: 'select',
                                id: 'unitId',
                                display: 'unitName',
                                label: 'invoice.form.unitName',
                                entity: 'unit',
                                valueMap: {
                                    'unitName': 'name',
                                    'unitId': 'id'
                                },
                                placeholder: 'invoice.form.unitName.placeholder',
                                options: { withHeaders: true },
                                isRequired: true,
                                defaultType: 'select'
                            }
                        ]
                    },

                ],
                buttons: [
                    {
                        type: 'delete',
                        size: 'icon',
                        icon: Icon_Delete,
                        variant: 'danger',
                        label: 'all.form.button.delete',
                        action: 'delete'
                    },
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'save'
                    }
                ],
                display: {
                    type: 'lines',
                    emptyMessage: 'invoice.form.product.missing.message',
                    emptyField: 'itemName',
                    editPos: '25%',
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1} (x${2})',
                                    icon: Icon_Boxes,
                                    mergeParams: ['itemName', 'itemQuantity']
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'itemDescription'
                                }
                            ]
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'amount',
                                    value: 'netSalesAmount'
                                },
                                {
                                    size: 'regular',
                                    type: 'currency'
                                }
                            ],
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'totals',
                    name: 'invoiceSummary',
                    width: '800px',
                    variant: 'transparent',
                    collapsible: false,
                    align: 'end'
                },
                display: {
                    fields: [
                        {
                            type: 'line',
                            size: 'regular',
                            label: 'Subtotal',
                            value: 'totalNetSalesAmount'
                        },
                        {
                            type: 'array',
                            subEntity: 'vatTaxes',
                            size: 'regular',
                            label: 'VAT ${1}% of ${2}',
                            labelParams: [['vatTaxRate', 100], 'vatTaxableAmount'],
                            value: 'vatTaxAmount'
                        },
                        {
                            type: 'line',
                            size: 'large',
                            label: 'Total Amount',
                            value: 'totalGrossSalesAmount'
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'invoiceTerms',
                    width: '800px'
                },
                groups: [
                    {
                        type: 'textarea',
                        id: 'terms',
                        label: 'invoice.form.terms',
                        placeholder: 'invoice.form.terms.placeholder',
                    }
                ],
                buttons: [
                    {
                        type: 'save',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'invoice.form.terms.missing.message',
                    emptyField: 'terms',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'invoice.form.terms',
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'terms'
                                }
                            ]
                        }
                    ]
                }
            },
        ],
        cardTitle: {
            title: 'all.form.button.more',
            buttons: [
                {
                    type: 'submit',
                    variant: 'primary',
                    label: 'all.form.button.save',
                    action: 'save',
                    confirm: false,
                }
            ],
            menuItems: [
                {
                    label: 'all.confirm.delete.accept',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'invoiceConfirmDelete'
                },
                {
                    label: 'all.menu.item.book',
                    action: 'book',
                    confirm: false,
                    preflight: true,
                    modalConfig: 'invoiceConfirmBook',
                    modalPreflight: 'invoicePreBook'
                }
            ],
            actions: {
                book: { message: 'invoice.action.book.message' }
            }

        },
        mobilePanel: {
            sections: [
                {
                    type: 'actionButton',
                    title: 'Show Preview',
                    entity: 'invoice',
                    modalGroups: [
                        {
                            type: 'pdf'
                        }
                    ]
                },
                {
                    type: 'optionPanel',
                    items: [
                        {
                            type: 'modal',
                            title: 'invoice.form.mobile.panel.payments',
                            action: 'showModal',
                            icon: Icon_Money,
                            modalGroups: 'invoicePayments'
                        },
                        {
                            type: 'modal',
                            title: 'invoice.form.mobile.panel.design',
                            action: 'showModal',
                            icon: Icon_Design,
                            modalGroups: 'invoiceDesign'
                        },
                        {
                            type: 'modal',
                            title: 'invoice.form.mobile.panel.options',
                            action: 'showModal',
                            icon: Icon_Gear,
                            modalGroups: 'invoiceOptions'
                        }
                    ]
                }
            ]
        },
        defaultData: [
            {
                id: 'font',
                defaultType: 'value',
                defaultValue: 'Courier',
            }
        ],
        fieldsLogic: ['paymentTermId', 'date', 'paymentDate']
    },
    contactInvoiceCard: {
        titleField: 'number',
        path: 'invoice',
        entity: 'invoice',
        newTitle: 'invoice.form.new.title',
        backOnSubmit: true,
        collapseOnSubmit: true,
        onDeleteConfirm: 'invoiceConfirmDelete',
        settingDestination: 'seller',
        subRoute: true,
        width: '',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'invoiceNumber',
                    width: '800px',
                },
                groups: [
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'nserie',
                                id: 'number',
                                label: 'invoice.form.number',
                                placeholder: 'invoice.form.number.placeholder',
                                isRequired: true,
                                defaultType: 'nserie',
                                entity: 'invoice'
                            },
                            {
                                label: 'invoice.form.date',
                                type: 'date',
                                id: 'date',
                                placeholder: 'invoice.form.date.placeholder',
                                isRequired: true,
                                defaultType: 'date',
                                align: 'right',
                                linkedFields: ['paymentTermId', 'date', 'paymentTermDays', 'paymentDate']
                            },
                        ]
                    },
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'select',
                                id: 'paymentTermId',
                                display: 'paymentTermId',
                                label: 'invoice.form.paymentTerm',
                                entity: 'paymentTerm',
                                valueMap: {
                                    'paymentTermId': 'id',
                                    'paymentTermName': 'name',
                                    'paymentTermDays': 'days',
                                    'paymentSchedule': 'paymentSchedule',
                                    'paymentInstallments': 'installments',
                                    'paymentIntervalDays': 'intervalDays'
                                },
                                options: { withHeaders: false, displayId: true },
                                isRequired: true,
                                defaultType: 'select',
                                linkedFields: ['paymentTermId', 'date', 'paymentTermDays', 'paymentDate', 'numPayments', 'totalGrossSalesAmount', 'id']
                            },
                            {
                                label: 'invoice.form.paymentDate',
                                type: 'date',
                                id: 'paymentDate',
                                placeholder: 'invoice.form.paymentDate.placeholder',
                                isRequired: true,
                                defaultType: 'date',
                                linkedFields: ['paymentTermId', 'date', 'paymentTermDays', 'paymentDate']
                            },
                        ]
                    },
                    {
                        type: 'textarea',
                        id: 'message',
                        label: 'invoice.form.message',
                        placeholder: 'invoice.form.message.placeholder',
                    }
                ],
                buttons: [
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'invoice.form.number.missing.message',
                    emptyField: 'number',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    prefix: 'invoice.form.number.title.prefix',
                                    size: 'title',
                                    type: 'field',
                                    value: 'number'
                                },
                                {
                                    size: 'regular',
                                    type: 'date',
                                    value: 'date'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'invoiceCustomer',
                    width: '800px'
                },
                groups: [
                    {
                        type: 'dualconnect',
                        id: 'customerId',
                        subId: 'contactPersonId',
                        mainEntity: 'contact',
                        subEntity: 'contactPerson',
                        display: 'customerName',
                        subDisplay: 'contactPersonName',
                        valueId: 'customerId',
                        label: 'invoice.form.customerName',
                        mainModalTitle: 'invoice.form.customer.modal.title',
                        subModalTitle: 'invoice.form.contactPerson.modal.title',
                        mainPlaceholder: 'invoice.form.customerName.placeholder',
                        subPlaceholder: 'invoice.form.contactPerson.name.placeholder',
                        mainValueMap: {
                            'customerName': 'name',
                            'customerId': 'id',
                            'customerEmail': 'email',
                            'customerAddress_city': 'address_city',
                            'customerAddress_postCode': 'address_postCode',
                            'customerAddress_street': 'address_street',
                            'customerCountry': 'country',
                            'customerCountryName': 'countryName',
                            'companyTypeId': 'companyTypeId'
                        },
                        subValueMap: {
                            'contactPersonName': 'name',
                            'contactPersonId': 'id',
                            'customerEmail': 'email'
                        },
                        options: { withHeaders: false },
                        isRequired: true,
                        showCondition: { field: 'companyTypeId', value: 'company' }
                    },
                    {
                        type: 'email',
                        id: 'customerEmail',
                        label: 'invoice.form.customerEmail',
                        placeholder: 'invoice.form.customerEmail.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'text',
                        label: 'invoice.form.customerAddress',
                        id: 'customerAddress_street',
                        placeholder: 'invoice.form.customerAddress.street',
                        autoComplete: 'address-line1'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'text',
                                id: 'customerAddress_postCode',
                                placeholder: 'invoice.form.customerAddress.postcode',
                                autoComplete: 'postal-code'
                            },
                            {
                                type: 'text',
                                id: 'customerAddress_city',
                                placeholder: 'invoice.form.customerAddress.city',
                                focus: false,
                                autoComplete: 'address-level2'
                            },

                        ]
                    },
                    {
                        type: 'country',
                        id: 'customerCountry',
                        display: 'customerCountryName',
                        defaultType: 'country',
                        valueMap: {
                            'customerCountry': 'id',
                            'customerCountryName': 'name',
                        },
                        placeholder: 'invoice.form.customerCountry',
                        fgMargin: '1.5rem'
                    },

                ],
                buttons: [
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'invoice.form.customer.missing.message',
                    editPos: '10%',
                    emptyField: 'customerName',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'customerName',
                                    icon: Icon_People,
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'customerEmail'
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '${1} - ${2} ${3} - ${4}',
                                    mergeParams: ['customerAddress_street', 'customerAddress_postCode', 'customerAddress_city', 'customerCountryName']
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'lines',
                    linesArray: 'lines',
                    linesAddAction: 'invoice.form.line.add.item',
                    name: 'invoiceLines',
                    width: '800px',
                    nFields: { 'netSalePrice': true, 'itemQuantity': true }
                },
                groups: [
                    {

                        type: 'connect',
                        id: 'itemId',
                        display: 'itemName',
                        label: 'invoice.form.itemName',
                        placeholder: 'invoice.form.itemName.placeholder',
                        modalTitle: 'invoice.form.item.modal.title',
                        entity: 'product',
                        valueMap: {
                            'itemId': 'id',
                            'itemName': 'name',
                            'itemDescription': 'description',
                            'netSalesPrice': 'netSalesPrice',
                            'unitId': 'unitId',
                            'unitName': 'unitName',
                            'itemTaxId': 'taxId',
                            'itemTaxName': 'taxName',
                            'itemTaxRate': 'taxRate'
                        },
                        options: { withHeaders: true, nFields: { 'netSalesPrice': true } },
                        isRequired: true
                    },
                    {
                        type: 'textarea',
                        rows: 2,
                        label: 'invoice.form.itemDescription',
                        id: 'itemDescription',
                        placeholder: 'invoice.form.itemDescription.placeholder'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'number',
                                id: 'netSalesPrice',
                                placeholder: 'invoice.form.netPrice',
                                label: 'invoice.form.netPrice',
                                variant: 'price',
                                isRequired: true,
                                decimals: 5,
                                defaultType: 'number',
                                defaultValue: 1,
                            },
                            {
                                type: 'select',
                                id: 'itemTaxId',
                                display: 'itemTaxName',
                                label: 'product.form.vatTax',
                                entity: 'vatTax',
                                valueMap: {
                                    'itemTaxName': 'name',
                                    'itemTaxId': 'id',
                                    'itemTaxRate': 'rate'
                                },
                                placeholder: 'product.form.unitName.placeholder',
                                options: { withHeaders: false },
                                isRequired: true,
                                defaultType: 'select'
                            }
                        ]
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'number',
                                id: 'itemQuantity',
                                placeholder: 'invoice.form.itemQuantity',
                                label: 'invoice.form.itemQuantity',
                                variant: 'quantity',
                                defaultType: 'number',
                                defaultValue: 1,
                                decimals: 5,
                                isRequired: true
                            },
                            {
                                type: 'select',
                                id: 'unitId',
                                display: 'unitName',
                                label: 'invoice.form.unitName',
                                entity: 'unit',
                                valueMap: {
                                    'unitName': 'name',
                                    'unitId': 'id'
                                },
                                placeholder: 'invoice.form.unitName.placeholder',
                                options: { withHeaders: true },
                                isRequired: true,
                                defaultType: 'select'
                            }
                        ]
                    },

                ],
                buttons: [
                    {
                        type: 'delete',
                        size: 'icon',
                        icon: Icon_Delete,
                        variant: 'danger',
                        label: 'all.form.button.delete',
                        action: 'delete'
                    },
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'save'
                    }
                ],
                display: {
                    type: 'lines',
                    emptyMessage: 'invoice.form.product.missing.message',
                    emptyField: 'itemName',
                    editPos: '25%',
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1} (x${2})',
                                    icon: Icon_Boxes,
                                    mergeParams: ['itemName', 'itemQuantity']
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'itemDescription'
                                }
                            ]
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'amount',
                                    value: 'netSalesAmount'
                                },
                                {
                                    size: 'regular',
                                    type: 'currency'
                                }
                            ],
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'totals',
                    name: 'invoiceSummary',
                    width: '800px',
                    variant: 'transparent',
                    collapsible: false,
                    align: 'end'
                },
                display: {
                    fields: [
                        {
                            type: 'line',
                            size: 'large',
                            label: 'inventory.shipment.total.products',
                            value: 'totalProducts'
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'invoiceTerms',
                    width: '800px'
                },
                groups: [
                    {
                        type: 'textarea',
                        id: 'terms',
                        label: 'invoice.form.terms',
                        placeholder: 'invoice.form.terms.placeholder',
                    }
                ],
                buttons: [
                    {
                        type: 'save',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'invoice.form.terms.missing.message',
                    emptyField: 'terms',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'invoice.form.terms',
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'terms'
                                }
                            ]
                        }
                    ]
                }
            },
        ],
        cardTitle: {
            title: 'all.form.button.more',
            buttons: [
                {
                    type: 'submit',
                    variant: 'primary',
                    label: 'all.form.button.save',
                    action: 'save',
                    confirm: false,
                }
            ],
            menuItems: [
                {
                    label: 'all.confirm.delete.accept',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'invoiceConfirmDelete'
                },
                {
                    label: 'all.menu.item.book',
                    action: 'book',
                    confirm: false,
                    preflight: true,
                    modalConfig: 'invoiceConfirmBook',
                    modalPreflight: 'invoicePreBook'
                }
            ],
            actions: {
                book: { message: 'invoice.action.book.message' }
            }

        },
        mobilePanel: {
            sections: [
                {
                    type: 'actionButton',
                    title: 'Show Preview',
                    entity: 'invoice',
                    modalGroups: [
                        {
                            type: 'pdf'
                        }
                    ]
                },
                {
                    type: 'optionPanel',
                    items: [
                        {
                            type: 'modal',
                            title: 'invoice.form.mobile.panel.payments',
                            action: 'showModal',
                            icon: Icon_Money,
                            modalGroups: 'invoicePayments'
                        },
                        {
                            type: 'modal',
                            title: 'invoice.form.mobile.panel.design',
                            action: 'showModal',
                            icon: Icon_Design,
                            modalGroups: 'invoiceDesign'
                        },
                        {
                            type: 'modal',
                            title: 'invoice.form.mobile.panel.options',
                            action: 'showModal',
                            icon: Icon_Gear,
                            modalGroups: 'invoiceOptions'
                        }
                    ]
                }
            ]
        },
        defaultData: [
            {
                id: 'font',
                defaultType: 'value',
                defaultValue: 'Courier',
            }
        ],
        fieldsLogic: ['paymentTermId', 'date', 'paymentDate']
    },
    creditNoteCard: {
        titleField: 'number',
        path: 'creditNote',
        entity: 'creditNote',
        newTitle: 'creditNote.form.new.title',
        backOnSubmit: false,
        submitBehaviour: 'list',
        collapseOnSubmit: true,
        onDeleteConfirm: 'invoiceConfirmDelete',
        settingDestination: 'seller',
        width: '',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'creditNoteNumber',
                    width: '800px',
                },
                groups: [
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'nserie',
                                id: 'number',
                                label: 'document.form.number',
                                placeholder: 'invoice.form.number.placeholder',
                                isRequired: true,
                                defaultType: 'nserie',
                                entity: 'invoice'
                            },
                            {
                                label: 'document.form.date',
                                type: 'date',
                                id: 'date',
                                placeholder: 'invoice.form.date.placeholder',
                                isRequired: true,
                                defaultType: 'date',
                                align: 'right',
                                linkedFields: ['paymentTermId', 'date', 'paymentTermDays', 'paymentDate']
                            },
                        ]
                    },
                    {
                        type: 'group',
                        fields: [
                            {
                            },
                            {
                                label: 'invoice.form.paymentDate',
                                type: 'date',
                                id: 'paymentDate',
                                placeholder: 'invoice.form.paymentDate.placeholder',
                                isRequired: true,
                                defaultType: 'date',
                                linkedFields: ['paymentTermId', 'date', 'paymentTermDays', 'paymentDate']
                            },
                        ]
                    },
                    {
                        type: 'textarea',
                        id: 'message',
                        label: 'invoice.form.message',
                        placeholder: 'invoice.form.message.placeholder',
                    }
                ],
                buttons: [
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'invoice.form.number.missing.message',
                    emptyField: 'number',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    prefix: 'document.form.number.title.prefix',
                                    size: 'title',
                                    type: 'field',
                                    value: 'number'
                                },
                                {
                                    size: 'regular',
                                    type: 'date',
                                    value: 'date'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'invoiceCustomer',
                    width: '800px',
                    readOnly: true
                },
                groups: [
                    {
                        type: 'dualconnect',
                        id: 'customerId',
                        subId: 'contactPersonId',
                        mainEntity: 'contact',
                        subEntity: 'contactPerson',
                        display: 'customerName',
                        subDisplay: 'contactPersonName',
                        valueId: 'customerId',
                        label: 'invoice.form.customerName',
                        mainModalTitle: 'invoice.form.customer.modal.title',
                        subModalTitle: 'invoice.form.contactPerson.modal.title',
                        mainPlaceholder: 'invoice.form.customerName.placeholder',
                        subPlaceholder: 'invoice.form.contactPerson.name.placeholder',
                        mainValueMap: {
                            'customerName': 'name',
                            'customerId': 'id',
                            'customerEmail': 'email',
                            'customerAddress_city': 'address_city',
                            'customerAddress_postCode': 'address_postCode',
                            'customerAddress_street': 'address_street',
                            'customerCountry': 'country',
                            'customerCountryName': 'countryName',
                            'companyTypeId': 'companyTypeId'
                        },
                        subValueMap: {
                            'contactPersonName': 'name',
                            'contactPersonId': 'id',
                            'customerEmail': 'email'
                        },
                        options: { withHeaders: false },
                        isRequired: true,
                        showCondition: { field: 'companyTypeId', value: 'company' }
                    },
                    {
                        type: 'email',
                        id: 'customerEmail',
                        label: 'invoice.form.customerEmail',
                        placeholder: 'invoice.form.customerEmail.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'text',
                        label: 'invoice.form.customerAddress',
                        id: 'customerAddress_street',
                        placeholder: 'invoice.form.customerAddress.street',
                        autoComplete: 'address-line1'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'text',
                                id: 'customerAddress_postCode',
                                placeholder: 'invoice.form.customerAddress.postcode',
                                autoComplete: 'postal-code'
                            },
                            {
                                type: 'text',
                                id: 'customerAddress_city',
                                placeholder: 'invoice.form.customerAddress.city',
                                focus: false,
                                autoComplete: 'address-level2'
                            },

                        ]
                    },
                    {
                        type: 'country',
                        id: 'customerCountry',
                        display: 'customerCountryName',
                        defaultType: 'country',
                        valueMap: {
                            'customerCountry': 'id',
                            'customerCountryName': 'name',
                        },
                        placeholder: 'invoice.form.customerCountry',
                        fgMargin: '1.5rem'
                    },

                ],
                buttons: [
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'invoice.form.customer.missing.message',
                    editPos: '10%',
                    emptyField: 'customerName',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'customerName',
                                    icon: Icon_People,
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'customerEmail'
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '${1} - ${2} ${3} - ${4}',
                                    mergeParams: ['customerAddress_street', 'customerAddress_postCode', 'customerAddress_city', 'customerCountryName']
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'lines',
                    linesArray: 'lines',
                    linesAddAction: 'invoice.form.line.add.item',
                    name: 'invoiceLines',
                    width: '800px',
                    nFields: { 'netSalePrice': true, 'itemQuantity': true }
                },
                groups: [
                    {

                        type: 'connect',
                        id: 'itemId',
                        display: 'itemName',
                        label: 'invoice.form.itemName',
                        placeholder: 'invoice.form.itemName.placeholder',
                        modalTitle: 'invoice.form.item.modal.title',
                        entity: 'product',
                        valueMap: {
                            'itemId': 'id',
                            'itemName': 'name',
                            'itemDescription': 'description',
                            'netSalesPrice': 'netSalesPrice',
                            'unitId': 'unitId',
                            'unitName': 'unitName',
                            'itemTaxId': 'taxId',
                            'itemTaxName': 'taxName',
                            'itemTaxRate': 'taxRate'
                        },
                        options: { withHeaders: true, nFields: { 'netSalesPrice': true } },
                        isRequired: true
                    },
                    {
                        type: 'textarea',
                        rows: 2,
                        label: 'invoice.form.itemDescription',
                        id: 'itemDescription',
                        placeholder: 'invoice.form.itemDescription.placeholder'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'number',
                                id: 'itemQuantity',
                                placeholder: 'invoice.form.itemQuantity',
                                label: 'invoice.form.itemQuantity',
                                variant: 'quantity',
                                defaultType: 'number',
                                defaultValue: 1,
                                decimals: 5,
                                isRequired: true
                            },
                            {
                                type: 'number',
                                id: 'netSalesPrice',
                                placeholder: 'invoice.form.netPrice',
                                label: 'invoice.form.netPrice',
                                variant: 'price',
                                isRequired: true,
                                decimals: 5,
                                defaultType: 'number',
                                defaultValue: 1,
                            }
                        ]
                    }
                ],
                buttons: [
                    {
                        type: 'delete',
                        size: 'icon',
                        icon: Icon_Delete,
                        variant: 'danger',
                        label: 'all.form.button.delete',
                        action: 'delete'
                    },
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'save'
                    }
                ],
                display: {
                    type: 'lines',
                    emptyMessage: 'invoice.form.product.missing.message',
                    emptyField: 'itemName',
                    editPos: '25%',
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1} (x${2})',
                                    icon: Icon_Boxes,
                                    mergeParams: ['itemName', 'itemQuantity']
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'itemDescription'
                                }
                            ]
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'amount',
                                    value: 'netSalesAmount'
                                },
                                {
                                    size: 'regular',
                                    type: 'currency'
                                }
                            ],
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'totals',
                    name: 'invoiceSummary',
                    width: '800px',
                    variant: 'transparent',
                    collapsible: false,
                    align: 'end'
                },
                display: {
                    fields: [
                        {
                            type: 'line',
                            size: 'regular',
                            label: 'Subtotal',
                            value: 'totalNetSalesAmount'
                        },
                        {
                            type: 'array',
                            subEntity: 'vatTaxes',
                            size: 'regular',
                            label: 'VAT ${1}% of ${2}',
                            labelParams: [['vatTaxRate', 100], 'vatTaxableAmount'],
                            value: 'vatTaxAmount'
                        },
                        {
                            type: 'line',
                            size: 'large',
                            label: 'Total Amount',
                            value: 'totalGrossSalesAmount'
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'invoiceTerms',
                    width: '800px'
                },
                groups: [
                    {
                        type: 'textarea',
                        id: 'terms',
                        label: 'invoice.form.terms',
                        placeholder: 'invoice.form.terms.placeholder',
                    }
                ],
                buttons: [
                    {
                        type: 'save',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'invoice.form.terms.missing.message',
                    emptyField: 'terms',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'invoice.form.terms',
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'terms'
                                }
                            ]
                        }
                    ]
                }
            },
        ],
        cardTitle: {
            title: 'all.form.button.more',
            buttons: [
                {
                    label: 'all.menu.item.save',
                    action: 'save',
                    confirm: false,
                    modalConfig: 'creditNoteConfirmSave',
                    variant: 'primary'
                }
            ],
            menuItems: [
                {
                    label: 'all.confirm.delete.accept',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'invoiceConfirmDelete'
                },
                {
                    label: 'all.menu.item.book',
                    action: 'book',
                    confirm: true,
                    preflight: true,
                    modalConfig: 'invoiceConfirmBook',
                    modalPreflight: 'invoicePreBook'
                },
            ],
            actions: {
                book: { message: 'invoice.action.book.message' }
            }

        },
        mobilePanel: {
            sections: [
                {
                    type: 'actionButton',
                    title: 'Show Preview',
                    entity: 'invoice',
                    modalGroups: [
                        {
                            type: 'pdf'
                        }
                    ]
                },
                {
                    type: 'optionPanel',
                    items: [
                        {
                            type: 'modal',
                            title: 'invoice.form.mobile.panel.payments',
                            action: 'showModal',
                            icon: Icon_Money,
                            modalGroups: 'invoicePayments'
                        },
                        {
                            type: 'modal',
                            title: 'invoice.form.mobile.panel.design',
                            action: 'showModal',
                            icon: Icon_Design,
                            modalGroups: 'invoiceDesign'
                        },
                        {
                            type: 'modal',
                            title: 'invoice.form.mobile.panel.options',
                            action: 'showModal',
                            icon: Icon_Gear,
                            modalGroups: 'invoiceOptions'
                        }
                    ]
                }
            ]
        },
        defaultData: [
            {
                id: 'font',
                defaultType: 'value',
                defaultValue: 'Courier',
            }
        ],
        fieldsLogic: ['paymentTermId', 'date', 'paymentDate']
    },
    salesSettingsCard: {
        titleField: 'settingsType',
        title: 'sales.settings.title',
        path: 'salesSettings',
        entity: 'salesSettings',
        newTitle: 'sales.settings.title',
        backOnSubmit: true,
        onDeleteConfirm: '',
        width: '756px',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'salesSettings_Logo',
                    width: '756px'
                },
                groups: [
                    {
                        type: 'upload',
                        id: 'invoiceLogo',
                        endpoint: 'salesSettings',
                        fieldId: 'sales',
                        table: 'settings',
                        field: 'invoiceLogo',
                        folder: 'invoiceLogo'
                    }
                ],
                buttons: [
                ],
                display: {
                    emptyMessage: 'Settings',
                    emptyField: 'companyName',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'Company Logo'
                                }
                            ],
                        }
                    ]

                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'salesSettings_CompanyData',
                    width: '756px'
                },
                groups: [
                    {
                        type: 'description',
                        label: 'sales.settings.companyData.title',
                        text: 'sales.settings.companyData.description'
                    },
                    {
                        type: 'separator',
                    },
                    {
                        type: 'text',
                        id: 'companyName',
                        label: 'sales.settings.companyName',
                        placeholder: 'sales.settings.companyName.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'text',
                        id: 'companyEmail',
                        label: 'sales.settings.companyEmail',
                        placeholder: 'sales.settings.companyEmail.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'text',
                        id: 'companyAddress_street',
                        label: 'sales.settings.companyAddress',
                        placeholder: 'sales.settings.companyAddress_street.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'text',
                                id: 'companyAddress_postCode',
                                placeholder: 'sales.settings.companyAddress.postcode',
                                autoComplete: 'postal-code'
                            },
                            {
                                type: 'text',
                                id: 'companyAddress_city',
                                placeholder: 'sales.settings.companyAddress.city',
                                focus: false,
                                autoComplete: 'address-level2'
                            },

                        ]
                    },

                ],
                buttons: [
                    {
                        variant: 'primary',
                        type: 'submit',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyMessage: 'Settings',
                    emptyField: 'companyName',
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'Company Information'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'companyName'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'companyAddress_street'
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '${1} - ${2}',
                                    mergeParams: ['companyAddress_postCode', 'companyAddress_city']
                                },
                            ],
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'companyEmail'
                                }
                            ]
                        }
                    ]

                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'salesSettings_VAT',
                    width: '756px'
                },
                groups: [
                    {
                        type: 'description',
                        label: 'sales.settings.vat.title',
                        text: 'sales.settings.vat.description'
                    },
                    {
                        type: 'separator',
                    },
                    {
                        type: 'select',
                        id: 'defaultItemTaxId',
                        display: 'defaultItemTaxName',
                        label: 'sales.settings.vatTax',
                        placeholder: 'sales.settings.vatTax.placeholder',
                        entity: 'vatTax',
                        valueMap: {
                            'defaultItemTaxName': 'name',
                            'defaultItemTaxId': 'id',
                            'defaultItemTaxRate': 'rate'
                        },
                        options: { withHeaders: false },
                        isRequired: true,
                        defaultType: 'select'
                    },
                ],
                buttons: [
                    {
                        variant: 'primary',
                        type: 'submit',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyMessage: 'Settings',
                    emptyField: 'companyName',
                    columns: [
                        {
                            size: 3,
                            grow: '30%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'VAT Information'
                                }
                            ],
                        },
                        {
                            size: 7,
                            grow: '70%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'Standard Rate'
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '${1}',
                                    mergeParams: ['defaultItemTaxName']
                                },

                            ]
                        }
                    ]

                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'salesSettings_Invoice',
                    width: '756px'
                },
                groups: [
                    {
                        type: 'description',
                        label: 'sales.settings.invoice.title',
                        text: 'sales.settings.invoice.description'
                    },
                    {
                        type: 'separator',
                    },
                    {
                        type: 'select',
                        id: 'invoiceLanguage',
                        display: 'invoiceLanguage',
                        label: 'sales.settings.language',
                        placeholder: 'sales.settings.language.placeholder',
                        entity: 'language',
                        valueMap: {
                            'invoiceLanguageName': 'name',
                            'invoiceLanguage': 'id'
                        },
                        options: { withHeaders: false, displayId: true },
                        isRequired: true,
                        defaultType: 'select'
                    },
                    {
                        type: 'select',
                        id: 'invoiceCurrency',
                        display: 'invoiceCurrency',
                        label: 'sales.settings.currency',
                        placeholder: 'sales.settings.currency.placeholder',
                        entity: 'currency',
                        valueMap: {
                            'invoiceCurrency': 'id',
                            'invoiceCurrencyName': 'name'
                        },
                        options: { withHeaders: false, displayId: true },
                        isRequired: true,
                        defaultType: 'select'
                    },
                    {
                        type: 'select',
                        id: 'defaultPaymentTermId',
                        display: 'defaultPaymentTermId',
                        label: 'sales.settings.paymentTerm',
                        placeholder: 'sales.settings.paymentTerm.placeholder',
                        entity: 'paymentTerm',
                        valueMap: {
                            'defaultPaymentTermId': 'id',
                            'defaultPaymentTermName': 'name',
                            'defaultPaymentTermDays': 'days'
                        },
                        options: { withHeaders: false, displayId: true, edit: true, list: '/sales/paymentTerm' },
                        isRequired: true,
                        defaultType: 'select'
                    },
                ],
                buttons: [
                    {
                        variant: 'primary',
                        type: 'submit',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyMessage: 'Settings',
                    emptyField: 'companyName',
                    columns: [
                        {
                            size: 3,
                            grow: '30%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'Invoice Settings'
                                }
                            ],
                        },
                        {
                            size: 7,
                            grow: '70%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'Standard Info'
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: 'Language: ${1}',
                                    mergeParams: ['invoiceLanguage']
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: 'Currency: ${1}',
                                    mergeParams: ['invoiceCurrency']
                                },

                            ]
                        }
                    ]

                }
            },
        ],
        cardTitle: {
            title: 'all.form.button.more',
            buttons: [
                {
                    type: 'submit',
                    variant: 'primary',
                    label: 'all.form.button.save',
                    action: 'save',
                    confirm: false,
                }
            ]
        }
    },
    paymentTermCard: {
        titleField: 'id',
        path: 'paymentTerm',
        entity: 'paymentTerm',
        newTitle: 'paymentTerm.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'paymentTermConfirmDelete',
        width: '756px',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'paymentTerm',
                    width: '480px'
                },
                groups: [
                    {
                        type: 'text',
                        id: 'id',
                        label: 'paymentTerm.form.id',
                        placeholder: 'paymentTerm.form.id.placeholder',
                        isRequired: true,
                        autoComplete: 'paymentTermCode'
                    },
                    {
                        type: 'text',
                        id: 'name',
                        label: 'paymentTerm.form.name',
                        placeholder: 'paymentTerm.form.name.placeholder',
                        isRequired: true,
                        autoComplete: 'paymentTermName'
                    },
                    {
                        type: 'separator',
                    },
                    {
                        type: 'description',
                        label: 'paymentTerm.form.days',
                        text: 'paymentTerm.form.days.description'
                    },

                    {
                        type: 'number',
                        id: 'days',
                        label: '',
                        variant: 'quantity',
                        decimals: 0,
                        placeholder: 'paymentTerm.form.days.placeholder',
                        isRequired: true,
                        defaultType: 'value',
                        defaultValue: 14

                    },
                    {
                        type: 'switch',
                        id: 'paymentSchedule',
                        switchText: 'Activate Schedule',
                    },
                    {
                        type: 'modal',
                        control: {
                            id: 'ScheduleOptions',
                            type: 'text',
                            label: 'Schedule Options',
                            placeholder: 'Select to enter the Options',
                        },
                        descriptions: [
                            {
                                type: 'text',
                                text: 'Number of Installments: '
                            },
                            {
                                type: 'field',
                                field: 'installments'
                            }
                        ],
                        modalGroups: [
                            {
                                type: 'number',
                                id: 'installments',
                                placeholder: 'Enter the number of Installments',
                                label: 'Number of Installments',
                                variant: 'quantity',
                                defaultType: 'number',
                                defaultValue: 3,
                                decimals: 0,
                                isRequired: true
                            },
                            {
                                type: 'number',
                                id: 'intervalDays',
                                placeholder: 'Choose time interval',
                                label: 'Interval Days',
                                variant: 'quantity',
                                defaultType: 'number',
                                defaultValue: 30,
                                decimals: 0,
                                isRequired: true
                            },
                        ]
                    },

                ],
                buttons: [
                    {
                        variant: 'primary',
                        type: 'submit',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyMessage: 'product.form.name.missing.message',
                    emptyField: 'name',
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'name'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'description'
                                }
                            ],
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1} DKK',
                                    mergeParams: ['netSalesPrice']
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '@${1}',
                                    mergeParams: ['unitName']
                                },

                            ]
                        }
                    ]

                }
            },
        ],
        cardTitle: {
            title: 'product.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'product.form.dropdown.menu01',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'productConfirmDelete'

                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    warehouseCard: {
        titleField: 'name',
        path: 'warehouse',
        entity: 'warehouse',
        newTitle: 'warehouse.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'warehouseConfirmDelete',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'warehouse',
                    width: '1024px'
                },
                groups: [
                    {
                        type: 'text',
                        id: 'name',
                        label: 'warehouse.form.name',
                        placeholder: 'warehouse.form.name.placeholder',
                        isRequired: true,
                        autoComplete: 'warehouse-name'
                    },
                    {
                        type: 'code',
                        id: 'code',
                        label: 'warehouse.form.code',
                        placeholder: 'warehouse.form.code.placeholder',
                        isRequired: true,
                        autoComplete: 'warehouse-code'
                    },
                    {
                        type: 'switch',
                        id: 'useHalls',
                        switchText: 'warehouse.form.useHalls',
                    },
                    {
                        type: 'modal',
                        control: {
                            id: 'addressModal',
                            type: 'text',
                            label: 'warehouse.form.address',
                            placeholder: 'warehouse.form.address.placeholder',
                        },
                        descriptions: [
                            {
                                type: 'field',
                                field: 'address_street'
                            },
                            {
                                type: 'text',
                                text: ', '
                            },
                            {
                                type: 'field',
                                field: 'address_postCode'
                            },
                            {
                                type: 'text',
                                text: ' '
                            },
                            {
                                type: 'field',
                                field: 'address_city'
                            }
                        ],
                        modalGroups: [
                            {
                                type: 'text',
                                label: 'warehouse.form.address.name',
                                id: 'address_name',
                                placeholder: 'warehouse.form.address.name_placeholder',
                                fgMargin: '1.5rem',
                                focus: true,
                                autoComplete: 'address-name'
                            },
                            {
                                type: 'text',
                                label: 'warehouse.form.address',
                                id: 'address_street',
                                placeholder: 'warehouse.form.address.street',
                                fgMargin: '1.5rem',
                                focus: true,
                                autoComplete: 'address-line1'
                            },
                            {
                                type: 'group',
                                label: 'warehouse.form.address',
                                focus: false,
                                fields: [
                                    {
                                        type: 'text',
                                        id: 'address_postCode',
                                        placeholder: 'warehouse.form.address.postcode',
                                        autoComplete: 'postal-code'
                                    },
                                    {
                                        type: 'text',
                                        id: 'address_city',
                                        placeholder: 'warehouse.form.address.city',
                                        focus: false,
                                        autoComplete: 'address-level2'

                                    },

                                ]
                            }
                        ]
                    },
                    {
                        label: 'all.country',
                        type: 'country',
                        id: 'country',
                        display: 'countryName',
                        valueMap: {
                            'country': 'id',
                            'countryName': 'name',
                        },
                        name: 'country',
                        placeholder: 'warehouse.form.country',
                        defaultType: 'country'
                    }
                ],
                buttons: [
                    {
                        type: 'submit',
                        variant: 'primary',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyField: 'name',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'name'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'countryName'
                                }
                            ]
                        }
                    ]
                }
            }],
        cardTitle: {
            title: 'warehouse.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'warehouse.form.dropdown.delete',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'confirmDelete'
                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    hallCard: {
        titleField: 'name',
        path: 'warehouse/:id/hall',
        entity: 'hall',
        newTitle: 'hall.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'hallConfirmDelete',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'hall',
                    width: '1024px'
                },
                groups: [
                    {
                        type: 'text',
                        id: 'name',
                        label: 'hall.form.name',
                        placeholder: 'hall.form.name.placeholder',
                        isRequired: true,
                        autoComplete: 'hall-name'
                    },
                    {
                        type: 'code',
                        id: 'code',
                        label: 'hall.form.name.code',
                        placeholder: 'hall.form.name.code.placeholder',
                        isRequired: true,
                        autoComplete: 'hall-code'
                    }
                ],
                buttons: [
                    {
                        type: 'submit',
                        variant: 'primary',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyMessage: 'warehouse.hall.list.table.name.missing.message',
                    emptyField: 'name',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'name'
                                }
                            ]
                        }
                    ]
                }
            }],
        cardTitle: {
            title: 'warehouse.hall.list.table.title',
            menuItems: [
                {
                    label: 'warehouse.hall.list.table.delete',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'confirmDelete'
                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    rackCard: {
        titleField: 'code',
        path: 'rack',
        entity: 'rack',
        newTitle: 'rack.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'rackConfirmDelete',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'rack',
                    width: '1024px'
                },
                groups: [
                    {
                        type: 'code',
                        id: 'code',
                        label: 'rack.form.code',
                        placeholder: 'rack.form.code.placeholder',
                        isRequired: true,
                        autoComplete: 'rack-code'
                    },
                    {
                        type: 'dualconnect',
                        id: 'warehouseId',
                        subId: 'hallId',
                        mainEntity: 'warehouse',
                        subEntity: 'hall',
                        display: 'warehouseName',
                        subDisplay: 'hallName',
                        valueId: 'warehouseId',
                        label: 'rack.form.warehouseName',
                        mainModalTitle: 'rack.form.warehouse.modal.title',
                        subModalTitle: 'rack.form.hall.modal.title',
                        mainPlaceholder: 'rack.form.warehouseName.placeholder',
                        subPlaceholder: 'rack.form.hall.placeholder',
                        mainValueMap: {
                            'warehouseName': 'name',
                            'warehouseId': 'id',
                            'warehouseCode': 'code',
                            'useHalls': 'useHalls'
                        },
                        subValueMap: {
                            'hallName': 'name',
                            'hallId': 'id',
                            'hallCode': 'code'
                        },
                        options: { withHeaders: false },
                        isRequired: true,
                        showCondition: { field: 'useHalls', value: true }
                    },
                    {
                        type: 'group',
                        fields: [{
                            type: 'number',
                            id: 'totalLevels',
                            label: 'rack.form.totalLevels',
                            placeholder: 'rack.form.totalLevels.placeholder',
                            variant: 'quantity',
                            decimals: 0,
                            isRequired: true,
                            defaultType: 'number',
                            defaultValue: 1
                        },
                        {
                            type: 'number',
                            id: 'totalPositions',
                            label: 'rack.form.totalPositions',
                            placeholder: 'rack.form.totalPositions.placeholder',
                            variant: 'quantity',
                            decimals: 0,
                            isRequired: true,
                            defaultType: 'number',
                            defaultValue: 1
                        }]
                    },
                    {
                        type: 'text-readOnly',
                        id: 'calculatedLocations',
                        label: 'rack.form.calculatedLocations',
                        placeholder: 'rack.form.calculatedLocations.placeholder',
                        isRequired: true,
                        defaultType: 'value',
                        defaultValue: '0'
                    }
                ],
                buttons: [
                    {
                        type: 'submit',
                        variant: 'primary',
                        label: 'all.form.button.update'
                    }
                ],
                display: {
                    emptyMessage: 'rack.form.name.missing.message',
                    emptyField: 'name',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'name'
                                }
                            ]
                        }
                    ]
                }
            }],
        cardTitle: {
            title: 'rack.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'rack.form.dropdown.delete',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'confirmDelete'
                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }

            ]
        }
    },
    locationCard: {
        titleField: 'id',
        path: 'location',
        entity: 'location',
        newTitle: 'location.form.new.title',
        backOnSubmit: true,
        onDeleteConfirm: 'rackConfirmDelete',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'location',
                    width: '1024px'
                },
                groups: [
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'text-readOnly',
                                id: 'warehouseCode',
                                label: 'location.form.warehouseCode',
                                placeholder: 'location.form.warehouseCode.placeholder',
                                isRequired: true,
                            },
                            {
                                type: 'text-readOnly',
                                id: 'hallCode',
                                label: 'location.form.hallCode',
                                placeholder: 'location.form.hallCode.placeholder',
                                isRequired: true,
                                hideIfEmpty: true
                            },
                            {
                                type: 'text-readOnly',
                                id: 'rackCode',
                                label: 'location.form.rackCode',
                                placeholder: 'location.form.rackCode.placeholder',
                                isRequired: true,
                            },
                        ]
                    },
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'text-readOnly',
                                id: 'level',
                                label: 'location.form.level',
                                placeholder: 'location.form.level.placeholder',
                                isRequired: true,
                            },
                            {
                                type: 'text-readOnly',
                                id: 'position',
                                label: 'location.form.position',
                                placeholder: 'location.form.position.placeholder',
                                isRequired: true,
                                hideIfEmpty: true
                            },
                            {
                                type: 'hidden',
                                id: 'position',
                                isRequired: true,
                            },
                        ]
                    }
                ],
                buttons: [
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.close',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'rack.form.name.missing.message',
                    emptyField: 'id',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'id'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'lines',
                    linesArray: 'inventory',
                    linesAddAction: 'inventory.adjustment.form.line.add.item',
                    name: 'locationLines',
                    width: '1024px',
                    noAddLine: true,
                    nFields: { 'itemQuantity': true }
                },
                groups: [
                    {

                        type: 'text-readOnly',
                        id: 'itemName',
                        label: 'inventory.adjustment.form.line.itemName',
                        placeholder: 'inventory.adjustment.form.line.itemName.placeholder',
                    },
                    {
                        type: 'text-readOnly',
                        rows: 2,
                        label: 'inventory.adjustment.form.line.itemDescription',
                        id: 'itemDescription',
                        placeholder: 'inventory.adjustment.form.line.itemDescription.placeholder'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'text-readOnly',
                                id: 'quantity',
                                placeholder: 'inventory.adjustment.form.line.quantity',
                                label: 'inventory.adjustment.form.line.quantity',
                            },
                            {
                                type: 'text-readOnly',
                                id: 'unitName',
                                label: 'inventory.adjustment.form.line.unitName',
                                placeholder: 'inventory.adjustment.form.line.unitName.placeholder',
                            }
                        ]
                    }
                ],
                buttons: [
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.close',
                        action: 'close'
                    }
                ],
                display: {
                    type: 'lines',
                    emptyMessage: 'inventory.adjustment.form.line.missing.message',
                    emptyField: 'itemName',
                    editPos: '25%',
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1}',
                                    icon: Icon_Boxes,
                                    mergeParams: ['itemName']
                                }
                            ]
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'quantity'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'unitName'
                                }
                            ],
                        }
                    ]
                }
            },
        ],
        cardTitle: {
            title: 'rack.form.dropdown.toggle',
            menuItems: [
                {
                    label: 'rack.form.dropdown.delete',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'confirmDelete'
                },
                {
                    label: 'Transactions',
                    action: 'transact'
                },
                {
                    label: 'Activities',
                    action: 'activate'
                }
            ]
        }
    },
    stockAdjustmentCard: {
        titleField: 'number',
        path: 'stockAdjustment',
        entity: 'stockAdjustment',
        newTitle: 'inventory.adjustment.form.title.new',
        backOnSubmit: true,
        onDeleteConfirm: 'confirmDelete',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'stockAdjustmentHeader',
                    width: '1024px'
                },
                groups: [
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'group',
                                direction: 'column',
                                fields: [
                                    {
                                        type: 'nserie',
                                        id: 'number',
                                        label: 'inventory.adjustment.form.numberAndDate',
                                        placeholder: 'inventory.adjustment.form.number.placeholder',
                                        isRequired: true,
                                        defaultType: 'nserie',
                                        entity: 'stockAdjustment',
                                    },
                                    {
                                        type: 'date',
                                        id: 'date',
                                        placeholder: 'inventory.adjustment.form.date.placeholder',
                                        isRequired: true,
                                        defaultType: 'date',
                                        align: 'right',
                                    },

                                ]
                            },
                            {
                                type: 'textarea',
                                id: 'note',
                                label: 'inventory.adjustment.form.note',
                                placeholder: 'inventory.adjustment.form.note.placeholder',
                                rows: 3
                            }
                        ]
                    }
                ],
                buttons: [
                ],
                display: {
                    emptyMessage: 'rack.form.name.missing.message',
                    emptyField: 'id',
                    columns: [
                        {
                            size: 1,
                            grow: '30%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'number'
                                },
                                { size: 'regular', type: 'date', value: 'date' }
                            ]
                        },
                        {
                            size: 2,
                            grow: '70%',
                            fields: [
                                { size: 'regular', type: 'field', value: 'note' }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'lines',
                    linesArray: 'lines',
                    linesAddAction: 'inventory.adjustment.form.line.add.item',
                    name: 'stockAdjustmentLines',
                    width: '1024px',
                    nFields: { 'itemQuantity': true }
                },
                groups: [
                    {

                        type: 'connect',
                        id: 'itemId',
                        display: 'itemName',
                        label: 'inventory.adjustment.form.line.itemName',
                        placeholder: 'inventory.adjustment.form.line.itemName.placeholder',
                        modalTitle: 'inventory.adjustment.form.item.modal.title',
                        entity: 'product',
                        persistValue: true,
                        valueMap: {
                            'itemId': 'id',
                            'itemName': 'name',
                            'itemDescription': 'description',
                            'unitId': 'unitId',
                            'unitName': 'unitName',
                        },
                        options: { withHeaders: true, nFields: { 'netSalesPrice': true } },
                        isRequired: true
                    },
                    {
                        type: 'text-readOnly',
                        rows: 2,
                        label: 'inventory.adjustment.form.line.itemDescription',
                        id: 'itemDescription',
                        placeholder: 'inventory.adjustment.form.line.itemDescription.placeholder'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'number',
                                id: 'quantity',
                                placeholder: 'inventory.adjustment.form.line.quantity',
                                label: 'inventory.adjustment.form.line.quantity',
                                variant: 'quantity',
                                defaultType: 'number',
                                defaultValue: 1,
                                decimals: 5,
                                isRequired: true
                            },
                            {
                                type: 'select',
                                id: 'unitId',
                                display: 'unitName',
                                label: 'inventory.adjustment.form.line.unitName',
                                entity: 'unit',
                                valueMap: {
                                    'unitName': 'name',
                                    'unitId': 'id'
                                },
                                placeholder: 'inventory.adjustment.form.line.unitName.placeholder',
                                options: { withHeaders: true },
                                isRequired: true,
                                defaultType: 'select'
                            }
                        ]
                    },
                    {
                        type: 'connect',
                        id: 'locationId',
                        display: 'locationId',
                        menuItemField: 'id',
                        label: 'inventory.adjustment.form.line.locationId',
                        placeholder: 'inventory.adjustment.form.line.locationId.placeholder',
                        modalTitle: 'inventory.adjustment.form.location.modal.title',
                        entity: 'location',
                        modalType: 'radioGroup',
                        persistValue: true,
                        radioGroups: [
                            {
                                id: 'warehouse',
                                title: 'inventory.adjustment.form.location.modal.warehouse',
                                entity: 'warehouse',
                                valueField: 'code',
                                idPrefix: 'W',
                                labelField: 'code',
                                icon: Icon_Warehouse
                            },
                            {
                                id: 'hall',
                                title: 'inventory.adjustment.form.location.modal.hall',
                                entity: 'warehouse/:id/hall',
                                dependsOn: 'warehouse', // Specifies which group's value to use for :id
                                valueField: 'code',
                                labelField: 'code',
                                idPrefix: 'H',
                                icon: Icon_Hall
                            },
                            {
                                id: 'rack',
                                title: 'inventory.adjustment.form.location.modal.rack',
                                entity: 'rack',
                                dependsOn: 'warehouse',
                                valueField: 'code',
                                labelField: 'code',
                                idPrefix: 'R',
                                columns: 6,
                                filter: [
                                    { param: 'warehouseCode', groupId: 'warehouse', },
                                    { param: 'hallCode', groupId: 'hall' }
                                ],
                                subRadio: [
                                    { id: 'level', idPrefix: 'L', valueField: 'totalLevels', title: 'inventory.adjustment.form.location.modal.level', columns: 6, value: 'auto' },
                                    { id: 'position', idPrefix: 'P', valueField: 'totalPositions', title: 'inventory.adjustment.form.location.modal.position', columns: 6, value: 'auto' }
                                ],
                            }
                        ],
                        valueMap: {
                            'locationId': 'id',
                            'warehouseId': 'warehouseId',
                            'warehouseCode': 'warehouseCode',
                            'hallId': 'hallId',
                            'hallCode': 'hallCode',
                            'rackId': 'rackId',
                            'rackCode': 'rackCode',
                            'level': 'level',
                            'position': 'position'
                        },
                        options: { withHeaders: false },
                        isRequired: true
                    },
                ],
                buttons: [
                    {
                        type: 'delete',
                        size: 'icon',
                        icon: Icon_Delete,
                        variant: 'danger',
                        label: 'all.form.button.delete',
                        action: 'delete'
                    },
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'save'
                    }
                ],
                display: {
                    type: 'lines',
                    emptyMessage: 'inventory.adjustment.form.line.missing.message',
                    emptyField: 'itemName',
                    editPos: '25%',
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1}',
                                    icon: Icon_Boxes,
                                    mergeParams: ['itemName']
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'locationId'
                                }
                            ]
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'quantity'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'unitName'
                                }
                            ],
                        }
                    ]
                }
            },
        ],
        cardTitle: {
            title: 'rack.form.dropdown.toggle',
            buttons: [
                {
                    type: 'submit',
                    variant: 'primary',
                    label: 'all.form.button.save',
                    action: 'save',
                    confirm: false,
                }
            ],
            menuItems: [
                {
                    label: 'all.confirm.delete.accept',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'confirmDelete'
                },
                {
                    label: 'all.menu.item.book',
                    action: 'book',
                    confirm: false,
                    preflight: true,
                    modalConfig: 'confirmBook',
                    modalPreflight: 'preBook'
                }
            ],
            actions: {
                book: { message: 'inventory.adjustment.action.book.message' }
            }
        }
    },
    stockMovementCard: {
        titleField: 'number',
        path: 'stockMovement',
        entity: 'stockMovement',
        newTitle: 'inventory.movement.form.title.new',
        backOnSubmit: true,
        onDeleteConfirm: 'confirmDelete',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'stockMovementHeader',
                    width: '1024px'
                },
                groups: [
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'group',
                                direction: 'column',
                                fields: [
                                    {
                                        type: 'nserie',
                                        id: 'number',
                                        label: 'inventory.movement.form.numberAndDate',
                                        placeholder: 'inventory.movement.form.number.placeholder',
                                        isRequired: true,
                                        defaultType: 'nserie',
                                        entity: 'stockAdjustment',
                                    },
                                    {
                                        type: 'date',
                                        id: 'date',
                                        placeholder: 'inventory.movement.form.date.placeholder',
                                        isRequired: true,
                                        defaultType: 'date',
                                        align: 'right',
                                    },

                                ]
                            },
                            {
                                type: 'textarea',
                                id: 'note',
                                label: 'inventory.movement.form.note',
                                placeholder: 'inventory.movement.form.note.placeholder',
                                rows: 3
                            }
                        ]
                    }
                ],
                buttons: [
                ],
                display: {
                    emptyMessage: 'rack.form.name.missing.message',
                    emptyField: 'id',
                    columns: [
                        {
                            size: 1,
                            grow: '30%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'number'
                                },
                                { size: 'regular', type: 'date', value: 'date' }
                            ]
                        },
                        {
                            size: 2,
                            grow: '70%',
                            fields: [
                                { size: 'regular', type: 'field', value: 'note' }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'lines',
                    linesArray: 'lines',
                    linesAddAction: 'inventory.movement.form.line.add.item',
                    name: 'stockMovementLines',
                    width: '1024px',
                    nFields: { 'itemQuantity': true }
                },
                groups: [
                    {

                        type: 'connect',
                        id: 'itemId',
                        display: 'itemName',
                        label: 'inventory.movement.form.line.itemName',
                        placeholder: 'inventory.movement.form.line.itemName.placeholder',
                        modalTitle: 'inventory.movement.form.item.modal.title',
                        entity: 'product',
                        valueMap: {
                            'itemId': 'id',
                            'itemName': 'name',
                            'itemDescription': 'description',
                            'unitId': 'unitId',
                            'unitName': 'unitName',
                        },
                        options: { withHeaders: true, nFields: { 'netSalesPrice': true } },
                        isRequired: true
                    },
                    {
                        type: 'text-readOnly',
                        rows: 2,
                        label: 'inventory.movement.form.line.itemDescription',
                        id: 'itemDescription',
                        placeholder: 'inventory.movement.form.line.itemDescription.placeholder'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'number',
                                id: 'quantity',
                                placeholder: 'inventory.movement.form.line.quantity',
                                label: 'inventory.movement.form.line.quantity',
                                variant: 'quantity',
                                defaultType: 'number',
                                defaultValue: 1,
                                decimals: 5,
                                isRequired: true
                            },
                            {
                                type: 'select',
                                id: 'unitId',
                                display: 'unitName',
                                label: 'inventory.movement.form.line.unitName',
                                entity: 'unit',
                                valueMap: {
                                    'unitName': 'name',
                                    'unitId': 'id'
                                },
                                placeholder: 'inventory.movement.form.line.unitName.placeholder',
                                options: { withHeaders: true },
                                isRequired: true,
                                defaultType: 'select'
                            }
                        ]
                    },
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'connect',
                                id: 'locationId',
                                subObject: 'origin',
                                display: 'locationId',
                                menuItemField: 'id',
                                label: 'inventory.movement.form.line.fromLocation',
                                placeholder: 'inventory.movement.form.line.locationId.placeholder',
                                modalTitle: 'inventory.movement.form.location.modal.title',
                                entity: 'location',
                                persistValue: true,
                                modalType: 'radioGroup',
                                radioGroups: [
                                    {
                                        id: 'warehouse',
                                        title: 'inventory.movement.form.location.modal.warehouse',
                                        entity: 'warehouse',
                                        valueField: 'code',
                                        idPrefix: 'W',
                                        labelField: 'code',
                                        icon: Icon_Warehouse
                                    },
                                    {
                                        id: 'hall',
                                        title: 'inventory.movement.form.location.modal.hall',
                                        entity: 'warehouse/:id/hall',
                                        dependsOn: 'warehouse', // Specifies which group's value to use for :id
                                        valueField: 'code',
                                        labelField: 'code',
                                        idPrefix: 'H',
                                        icon: Icon_Hall
                                    },
                                    {
                                        id: 'rack',
                                        title: 'inventory.movement.form.location.modal.rack',
                                        entity: 'rack',
                                        dependsOn: 'warehouse',
                                        valueField: 'code',
                                        labelField: 'code',
                                        idPrefix: 'R',
                                        columns: 6,
                                        filter: [
                                            { param: 'warehouseCode', groupId: 'warehouse', },
                                            { param: 'hallCode', groupId: 'hall' }
                                        ],
                                        subRadio: [
                                            { id: 'level', idPrefix: 'L', valueField: 'totalLevels', title: 'inventory.movement.form.location.modal.level', columns: 6, value: 'auto' },
                                            { id: 'position', idPrefix: 'P', valueField: 'totalPositions', title: 'inventory.movement.form.location.modal.position', columns: 6, value: 'auto' }
                                        ],
                                    }
                                ],
                                valueMap: {
                                    'locationId': 'id',
                                    'warehouseId': 'warehouseId',
                                    'warehouseCode': 'warehouseCode',
                                    'hallId': 'hallId',
                                    'hallCode': 'hallCode',
                                    'rackId': 'rackId',
                                    'rackCode': 'rackCode',
                                    'level': 'level',
                                    'position': 'position'
                                },
                                options: { withHeaders: false },
                                isRequired: true
                            },
                            {
                                type: 'connect',
                                id: 'locationId',
                                subObject: 'destination',
                                display: 'locationId',
                                menuItemField: 'id',
                                label: 'inventory.movement.form.line.toLocation',
                                placeholder: 'inventory.movement.form.line.locationId.placeholder',
                                modalTitle: 'inventory.movement.form.location.modal.title',
                                entity: 'location',
                                persistValue: true,
                                modalType: 'radioGroup',
                                radioGroups: [
                                    {
                                        id: 'warehouse',
                                        title: 'inventory.movement.form.location.modal.warehouse',
                                        entity: 'warehouse',
                                        valueField: 'code',
                                        idPrefix: 'W',
                                        labelField: 'code',
                                        icon: Icon_Warehouse
                                    },
                                    {
                                        id: 'hall',
                                        title: 'inventory.movement.form.location.modal.hall',
                                        entity: 'warehouse/:id/hall',
                                        dependsOn: 'warehouse', // Specifies which group's value to use for :id
                                        valueField: 'code',
                                        labelField: 'code',
                                        idPrefix: 'H',
                                        icon: Icon_Hall
                                    },
                                    {
                                        id: 'rack',
                                        title: 'inventory.movement.form.location.modal.rack',
                                        entity: 'rack',
                                        dependsOn: 'warehouse',
                                        valueField: 'code',
                                        labelField: 'code',
                                        idPrefix: 'R',
                                        columns: 6,
                                        filter: [
                                            { param: 'warehouseCode', groupId: 'warehouse', },
                                            { param: 'hallCode', groupId: 'hall' }
                                        ],
                                        subRadio: [
                                            { id: 'level', idPrefix: 'L', valueField: 'totalLevels', title: 'inventory.movement.form.location.modal.level', columns: 6, value: 'auto' },
                                            { id: 'position', idPrefix: 'P', valueField: 'totalPositions', title: 'inventory.movement.form.location.modal.position', columns: 6, value: 'auto' }
                                        ],
                                    }
                                ],
                                valueMap: {
                                    'locationId': 'id',
                                    'warehouseId': 'warehouseId',
                                    'warehouseCode': 'warehouseCode',
                                    'hallId': 'hallId',
                                    'hallCode': 'hallCode',
                                    'rackId': 'rackId',
                                    'rackCode': 'rackCode',
                                    'level': 'level',
                                    'position': 'position'
                                },
                                options: { withHeaders: false },
                                isRequired: true
                            }
                        ]
                    }
                ],
                buttons: [
                    {
                        type: 'delete',
                        size: 'icon',
                        icon: Icon_Delete,
                        variant: 'danger',
                        label: 'all.form.button.delete',
                        action: 'delete'
                    },
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'save'
                    }
                ],
                display: {
                    type: 'lines',
                    emptyMessage: 'inventory.movement.form.line.missing.message',
                    emptyField: 'itemName',
                    editPos: '25%',
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1}',
                                    icon: Icon_Boxes,
                                    mergeParams: ['itemName']
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'locationId'
                                }
                            ]
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'quantity'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'unitName'
                                }
                            ],
                        }
                    ]
                }
            },
        ],
        cardTitle: {
            title: 'rack.form.dropdown.toggle',
            buttons: [
                {
                    type: 'submit',
                    variant: 'primary',
                    label: 'all.form.button.save',
                    action: 'save',
                    confirm: false,
                }
            ],
            menuItems: [
                {
                    label: 'all.confirm.delete.accept',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'confirmDelete'
                },
                {
                    label: 'all.menu.item.book',
                    action: 'book',
                    confirm: false,
                    preflight: true,
                    modalConfig: 'confirmBook',
                    modalPreflight: 'preBook'
                }
            ],
            actions: {
                book: { message: 'inventory.movement.action.book.message' }
            }
        }
    },
    stockShipmentCard: {
        titleField: 'number',
        path: 'stockShipment',
        entity: 'stockShipment',
        newTitle: 'inventory.shipment.form.title.new',
        backOnSubmit: true,
        backOverride: '/inventory/shipment',
        collapseOnSubmit: true,
        onDeleteConfirm: 'confirmDelete',
        settingDestination: 'seller',
        width: '',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'shipmentNumber',
                    width: '800px',
                },
                groups: [
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'nserie',
                                id: 'number',
                                label: 'inventory.shipment.form.number',
                                placeholder: 'inventory.shipment.form.number.placeholder',
                                isRequired: true,
                                defaultType: 'nserie',
                                entity: 'stockShipment'
                            },
                            {
                                label: 'inventory.shipment.form.date',
                                type: 'date',
                                id: 'date',
                                placeholder: 'inventory.shipment.form.date.placeholder',
                                isRequired: true,
                                defaultType: 'date',
                                align: 'right'
                            },
                        ]
                    },
                    {
                        type: 'group',
                        fields: [
                            {
                                type: 'connect',
                                id: 'id',
                                subObject: 'warehouse',
                                display: 'name',
                                label: 'inventory.shipment.form.warehouse',
                                entity: 'warehouse',
                                valueMap: {
                                    'name': 'name',
                                    'id': 'id',
                                    'code': 'code',
                                    'address_name': 'address_name',
                                    'address_street': 'address_street',
                                    'address_postCode': 'address_postCode',
                                    'address_city': 'address_city',
                                    'address_region': 'address_region',
                                    'aountry': 'country',
                                    'countryName': 'countryName',
                                },
                                options: { withHeaders: false },
                                isRequired: true,
                                showCondition: { field: 'warehouseUseHalls', value: true }
                            }, {}
                        ]
                    },
                    {
                        type: 'textarea',
                        id: 'message',
                        label: 'inventory.shipment.form.message',
                        placeholder: 'inventory.shipment.form.message.placeholder',
                    }
                ],
                buttons: [
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'inventory.shipment.form.number.missing.message',
                    emptyField: 'number',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    prefix: 'inventory.shipment.form.number.title.prefix',
                                    size: 'title',
                                    type: 'field',
                                    value: 'number'
                                },
                                {
                                    size: 'regular',
                                    type: 'date',
                                    value: 'date'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'customer',
                    width: '800px'
                },
                groups: [
                    {
                        type: 'dualconnect',
                        id: 'customerId',
                        subId: 'contactPersonId',
                        mainEntity: 'contact',
                        subEntity: 'contactPerson',
                        display: 'customerName',
                        subDisplay: 'contactPersonName',
                        valueId: 'customerId',
                        label: 'customer.form.customerName',
                        mainModalTitle: 'customer.form.customer.modal.title',
                        subModalTitle: 'customer.form.contactPerson.modal.title',
                        mainPlaceholder: 'customer.form.customerName.placeholder',
                        subPlaceholder: 'customer.form.contactPerson.name.placeholder',
                        mainValueMap: {
                            'customerName': 'name',
                            'customerId': 'id',
                            'customerEmail': 'email',
                            'customerAddress_city': 'address_city',
                            'customerAddress_postCode': 'address_postCode',
                            'customerAddress_street': 'address_street',
                            'customerCountry': 'country',
                            'customerCountryName': 'countryName',
                            'companyTypeId': 'companyTypeId'
                        },
                        subValueMap: {
                            'contactPersonName': 'name',
                            'contactPersonId': 'id',
                            'customerEmail': 'email'
                        },
                        options: { withHeaders: false },
                        isRequired: true,
                        showCondition: { field: 'companyTypeId', value: 'company' }
                    },
                    {
                        type: 'email',
                        id: 'customerEmail',
                        label: 'customer.form.customerEmail',
                        placeholder: 'customer.form.customerEmail.placeholder',
                        isRequired: true
                    },
                    {
                        type: 'text',
                        label: 'customer.form.customerAddress',
                        id: 'customerAddress_street',
                        placeholder: 'customer.form.customerAddress.street',
                        autoComplete: 'address-line1'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'text',
                                id: 'customerAddress_postCode',
                                placeholder: 'customer.form.customerAddress.postcode',
                                autoComplete: 'postal-code'
                            },
                            {
                                type: 'text',
                                id: 'customerAddress_city',
                                placeholder: 'customer.form.customerAddress.city',
                                focus: false,
                                autoComplete: 'address-level2'
                            },

                        ]
                    },
                    {
                        type: 'country',
                        id: 'customerCountry',
                        display: 'customerCountryName',
                        defaultType: 'country',
                        valueMap: {
                            'customerCountry': 'id',
                            'customerCountryName': 'name',
                        },
                        placeholder: 'customer.form.customerCountry',
                        fgMargin: '1.5rem'
                    },

                ],
                buttons: [
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'customer.form.customer.missing.message',
                    editPos: '10%',
                    emptyField: 'customerName',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'customerName',
                                    icon: Icon_People,
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'customerEmail'
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '${1} - ${2} ${3} - ${4}',
                                    mergeParams: ['customerAddress_street', 'customerAddress_postCode', 'customerAddress_city', 'customerCountryName']
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'lines',
                    linesArray: 'lines',
                    linesAddAction: 'inventory.shipment.form.line.add.item',
                    name: 'shipmentLines',
                    width: '800px',
                    nFields: { 'itemQuantity': true }
                },
                groups: [
                    {

                        type: 'connect',
                        id: 'itemId',
                        display: 'itemName',
                        label: 'product.form.itemName',
                        placeholder: 'product.form.itemName.placeholder',
                        modalTitle: 'product.form.item.modal.title',
                        entity: 'product',
                        valueMap: {
                            'itemId': 'id',
                            'itemName': 'name',
                            'itemDescription': 'description',
                            'unitId': 'unitId',
                            'unitName': 'unitName',
                        },
                        options: { withHeaders: true, nFields: { 'netSalesPrice': true } },
                        isRequired: true
                    },
                    {
                        type: 'textarea',
                        rows: 2,
                        label: 'product.form.itemDescription',
                        id: 'itemDescription',
                        placeholder: 'product.form.itemDescription.placeholder'
                    },
                    {
                        type: 'group',
                        focus: false,
                        fields: [
                            {
                                type: 'number',
                                id: 'itemQuantity',
                                placeholder: 'product.form.itemQuantity',
                                label: 'product.form.itemQuantity',
                                variant: 'quantity',
                                defaultType: 'number',
                                defaultValue: 1,
                                decimals: 5,
                                isRequired: true
                            },
                            {
                                type: 'select',
                                id: 'unitId',
                                display: 'unitName',
                                label: 'product.form.unitName',
                                entity: 'unit',
                                valueMap: {
                                    'unitName': 'name',
                                    'unitId': 'id'
                                },
                                placeholder: 'product.form.unitName.placeholder',
                                options: { withHeaders: true },
                                isRequired: true,
                                defaultType: 'select'
                            }
                        ]
                    },
                    {
                        type: "modal",
                        label: "This is my Modal",
                        control: {
                            id: "locationsModal",
                            type: "text",
                            label: "inventory.shipment.form.lines.location",
                            placeholder: "inventory.shipment.form.lines.location.placeholder",
                        },
                        descriptions: [
                            {
                                type: "label",
                                text: "inventory.shipment.form.lines.location.quantity.allocated",
                            },
                            {
                                type: "text",
                                text: " ",
                            },
                            {
                                type: "field",
                                field: "allocatedQuantity",
                            }
                        ],
                        validation: {
                            "field": "itemQuantity",
                            "operator": "===",
                            "value": "allocatedQuantity",
                        },
                        modalGroups: 'selectLocations',
                    }
                ],
                buttons: [
                    {
                        type: 'delete',
                        size: 'icon',
                        icon: Icon_Delete,
                        variant: 'danger',
                        label: 'all.form.button.delete',
                        action: 'delete'
                    },
                    {
                        type: 'close',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'save'
                    }
                ],
                display: {
                    type: 'lines',
                    emptyMessage: 'product.form.product.missing.message',
                    emptyField: 'itemName',
                    editPos: '25%',
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1} (x${2})',
                                    icon: Icon_Boxes,
                                    mergeParams: ['itemName', 'itemQuantity']
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'itemDescription'
                                }
                            ]
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'amount',
                                    value: 'itemQuantity'
                                },
                                {
                                    size: 'regular',
                                    type: 'unitName'
                                }
                            ],
                        }
                    ]
                },
                init: [
                    {
                        type: 'number',
                        id: 'allocatedQuantity',
                        defaultType: 'number',
                        defaultValue: 0,
                    },
                ]
            },
            {
                meta: {
                    type: 'totals',
                    name: 'shipmentSummary',
                    width: '800px',
                    variant: 'transparent',
                    collapsible: false,
                    align: 'end'
                },
                display: {
                    fields: [
                        {
                            type: 'line',
                            size: 'large',
                            label: 'inventory.shipment.total.products',
                            value: 'totalProducts'
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'form',
                    name: 'invoiceTerms',
                    width: '800px'
                },
                groups: [
                    {
                        type: 'textarea',
                        id: 'terms',
                        label: 'inventory.shipment.form.terms',
                        placeholder: 'inventory.shipment.form.terms.placeholder',
                    }
                ],
                buttons: [
                    {
                        type: 'save',
                        variant: 'primary',
                        label: 'all.form.button.save',
                        action: 'close'
                    }
                ],
                display: {
                    emptyMessage: 'inventory.shipment.form.terms.missing.message',
                    emptyField: 'terms',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'invoice.form.terms',
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'terms'
                                }
                            ]
                        }
                    ]
                }
            },
        ],
        cardTitle: {
            title: 'all.form.button.more',
            buttons: [
                {
                    type: 'submit',
                    variant: 'primary',
                    label: 'all.form.button.save',
                    action: 'save',
                    confirm: false,
                }
            ],
            menuItems: [
                {
                    label: 'all.confirm.delete.accept',
                    action: 'delete',
                    confirm: true,
                    modalConfig: 'invoiceConfirmDelete'
                },
                {
                    label: 'all.menu.item.book',
                    action: 'book',
                    confirm: false,
                    preflight: true,
                    modalConfig: 'invoiceConfirmBook',
                    modalPreflight: 'invoicePreBook'
                }
            ],
            actions: {
                book: { message: 'invoice.action.book.message' }
            }

        },
        mobilePanel: {
            sections: [
                {
                    type: 'actionButton',
                    title: 'Show Preview',
                    entity: 'stockShipment',
                    modalGroups: [
                        {
                            type: 'pdf'
                        }
                    ]
                },
                {
                    type: 'optionPanel',
                    items: [
                        {
                            type: 'modal',
                            title: 'inventory.shipment.form.mobile.panel.design',
                            action: 'showModal',
                            icon: Icon_Design,
                            modalGroups: 'inventoryShipmentDesign'
                        }
                    ]
                }
            ]
        },
        defaultData: [
            {
                id: 'font',
                defaultType: 'value',
                defaultValue: 'Courier',
            }
        ],
        fieldsLogic: ['paymentTermId', 'date', 'paymentDate']
    },
    inventorySettingsCard: {
        titleField: 'settingsType',
        title: 'inventory.settings.title',
        path: 'inventorySettings',
        entity: 'inventorySettings',
        newTitle: 'inventory.settings.title',
        backOnSubmit: true,
        onDeleteConfirm: '',
        width: '756px',
        sections: [
            {
                meta: {
                    type: 'form',
                    name: 'inventorySettings_Logo',
                    width: '756px'
                },
                groups: [
                    {
                        type: 'upload',
                        id: 'inventoryLogo',
                        endpoint: 'inventorySettings',
                        fieldId: 'inventory',
                        table: 'settings',
                        field: 'inventoryLogo',
                        folder: 'inventoryLogo'
                    }
                ],
                buttons: [
                ],
                display: {
                    emptyMessage: 'inventory.settings.logo.missing.message',
                    emptyField: 'inventoryLogo',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'Company Logo'
                                }
                            ],
                        }
                    ]

                }
            }
        ],
        cardTitle: {
            title: 'all.form.button.more',
            buttons: [
                {
                    type: 'submit',
                    variant: 'primary',
                    label: 'all.form.button.save',
                    action: 'save',
                    confirm: false,
                }
            ]
        }
    }

    //End of Data
}