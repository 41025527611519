function updateState(state, path, updateValue) {
    if (!Array.isArray(path) || path.length === 0) {
        throw new Error("Path must be a non-empty array.");
    }

    const [key, index] = path;

    return {
        ...state,
        [key]: Array.isArray(state[key])
            ? state[key].map((item, i) => (i === index ? updateValue : item))
            : updateValue,
    };
}

export default updateState;