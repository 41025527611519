import { useState, useEffect } from "react"
import styled from "styled-components"
import useTranslation from "../../hooks/useTranslation"
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom"

//Import used components
import ModalForm from "../cardView/ModalForm"
import { useStore } from "../pageView/Page"
import processModalGroups from "../../utils/model/processModalGroups"

const MenuItem = styled.div`
    width: 4.5rem;
    height: 3rem;

    p {
        font-size: 0.65rem;
        padding-top: 0rem;
        margin-block-start: 0.25rem
}

`

const MenuIconWrapper = styled.div`
    width: 36px;
    height: 36px;
    margin: auto;
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
`

const MobileBarLink = styled(Link)`
    color: ${({ theme }) => theme.font.color};
    list-style: none;
    text-decoration: none;
   &:hover {
        color: ${({ theme }) => theme.font.active};
        cursor: pointer;
    }
    &.active {
        color: ${({ theme }) => theme.font.active};
        cursor: pointer;
    }


    &.disabled {
        pointer-events: none;
    }

`

const MobileBarButton = styled.div`
    color: ${({ theme }) => theme.font.color};
    list-style: none;
    text-decoration: none;
   &:hover {
        color: ${({ theme }) => theme.font.active};
        cursor: pointer;
    }
    &.active {
        color: ${({ theme }) => theme.font.active};
        cursor: pointer;
    }
`

const MobileOptionPanelItem = ({ item, useStore }) => {
    const [showModal, setShowModal] = useState(false)

    const { t } = useTranslation()

    const location = useLocation()
    const isActivePath = (location.pathname === item.path);

    const clickItem = (e) => {
        if (!item.path) {
            // If there's no path, prevent default navigation
            e.preventDefault()
        }
        setShowModal(true)
    }

    //Determines if it needs to load ModalGroups or if it is embedded
    const modalGroups = processModalGroups(item)

    // If there's no path, render a button instead of a link
    const ContentWrapper = item.path ? MobileBarLink : MobileBarButton

    return (
        <>
            <MenuItem>
                <ContentWrapper 
                    onClick={clickItem} 
                    className={isActivePath ? 'active' : ''} 
                    to={item.path || undefined}
                >
                    <MenuIconWrapper >
                        {item.icon}
                    </MenuIconWrapper>
                    <p>{t(item.title)}</p>
                </ContentWrapper>
            </MenuItem>
            {showModal && <ModalForm
                isVisible={showModal}
                setIsVisible={setShowModal}
                modalTitle={'Options'}
                groups={modalGroups}
                //validated={validated}
                section={item}
                mode='fullscreen'
                useStore={useStore}
            >
            </ModalForm>}
        </>
    )
}

export default MobileOptionPanelItem
