import React from 'react';
import styled from "styled-components"
import DisplayTotalsSubLines, { DisplayTotalsLine } from "./DisplayTotalsSubLines";
import { useStore } from "../pageView/Page";
import useTranslation from '../../hooks/useTranslation';

const ContentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 0rem;
    margin-bottom: 1rem;
    max-width: ${ ({width}) => width? width : <></>}
`
const Section = styled.div`
    text-align: right;
`;

const DisplayTotals = ({ section }) => {
    const [data] = useStore( s => s)
    //logger.warn('DisplayTotals Data ', data)
    const { t } = useTranslation()

    return (
        <>
            <ContentWrapper width={section.meta.width}>
                <Section>
                    {section.display.fields && section.display.fields.map(
                        (field, i) => {
                            return (
                                <React.Fragment key={i}>
                                    {field.type === 'line' &&
                                        <DisplayTotalsLine
                                            value={data[field.value]}
                                            label={t(field.label)}
                                            size={field.size}
                                            currency={data.currency}
                                        />
                                    }
                                    {field.type === 'array' &&
                                        <>
                                            <DisplayTotalsSubLines data={data} field={field} />
                                        </>}
                                </React.Fragment>

                            )
                        }

                    )}
                </Section>
            </ContentWrapper>
        </>
    )
}

export default DisplayTotals