// Helper function to get nested state value
export const getNestedValue = (obj, path) => {
    return path.reduce((current, key) => (current ? current[key] : undefined), obj)
}

// Helper function to set nested state value
export const setNestedValue = (obj, path, value) => {
    if (path.length === 0) return value

    const [first, ...rest] = path
    const newObj = Array.isArray(obj) ? [...obj] : { ...obj }

    newObj[first] = setNestedValue(
        obj[first] ?? (typeof rest[0] === 'number' ? [] : {}),
        rest,
        value
    )

    return newObj
}

export const getNestedValueKey = (obj, path, field, subObject = null) => {
    const value = getNestedValue(obj, path)
    if (!value) return undefined
    
    if (subObject) {
        return value[subObject] ? value[subObject][field] : undefined
    }
    return value[field]
}
