import React from 'react';
import styled from "styled-components";
import { Row, Cell } from '../generic/Layout'


const OptionName = styled.p`
    font-size: var(--font-XS);
    margin: 0;
    padding-top:0.20rem;
    padding-left: 0.5rem;

    @media (max-width: 578px) {
        font-size: var(--font-M);
    }

`
const HeaderItem = styled.li`
    pointer-events: none;
    color: ${ ({theme}) => theme.font.highlight };
    padding-left: 0.5rem;

`

const ListItem = styled.li`
    cursor: pointer;
    &.active, &:hover {
    background-color: ${({ theme }) => theme.dropdown.selectedBackground};
    border-color: #d3d5d7;
    color: ${({ theme }) => theme.dropdown.selectedFont};
    }   

    @media (max-width: 578px) {
    }
`

const SelectMenuItem = ({
    name,
    id,
    header,
    onSelectItem,
    isHighlighted,
    displayId = false,
}) => {

    //If the name is undefined, we set it to the id
    if (name === undefined || name === null) {
        name = id;
    }
    
    return (
        <React.Fragment>
            {header ? <HeaderItem id={id}>{name}</HeaderItem> :
                <ListItem
                    className={`list-group-item ${isHighlighted ? "active highlighted" : ""
                        }`}
                    onClick={onSelectItem}
                >
                    <Row >
                        <Cell $flex='10 0 0%'>
                        {displayId && <OptionName> {`${id} - ${name}`} </OptionName>}
                        {!displayId && <OptionName> {`${name}`} </OptionName>}
                        </Cell>
                    </Row>
                </ListItem>
            }
        </React.Fragment>

    );
};

export default SelectMenuItem;