import { FormGroup } from "../form/FormComponents"
import analyzeSerie from '../../../../common/logic/validate/analyzeSerie'

// Setup Use of API
import useApi from '../../hooks/useApi';
import { useQuery } from 'react-query';
import useAuth from "../../hooks/useAuth";

const NSerieInput = ({ item, value, placeholder, autocomplete, setValue, validated, ref, entity }) => {
    
    //Setting up API 
    const { getMe } = useApi();
    const {Auth} = useAuth()
    const currentOrg = Auth.orgId

    //Api Retrieval function to get list
    const getData = async (key) => {
        const orgId = key.queryKey[1]
        const resource = `organization/${orgId}`
        const response = await getMe(resource)
        return response
    }

    //Request to get Countries from API 
    const result = useQuery(['orgs', currentOrg], getData, { keepPreviousData: true })

    // we check the nSerie and we set it to red if it is not valid     
    const serie = analyzeSerie(value)
    let isRed = serie.valid? false: true


    const look ={
        color: isRed? 'red' : 'inherit'
    }
    
    if (result.isLoading) {
        return <span>Loading...</span>
    }

    if (result.isError) {
        return <span>Error: {result.error? result.error.message : 'Internet Issues'}</span>
    }

    if (result.isSuccess) {
        if (!value) {
            //loadedValue = result.data.data.profile.nSeries[entity].nextValue
           // setValue()
        }
        return (
            <FormGroup.Control style={look} type={item.type} name={item.id}
                value={value} onChange={setValue} placeholder={placeholder}
                autoComplete={autocomplete}
                {...(validated && item.isRequired && { required: true })}
                {...(ref && { ref })}
            />
        )
    }




}

export default NSerieInput